import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoSubscriptionComponent } from './no-subscription/no-subscription.component';



@NgModule({
  declarations: [
    ErrorPageComponent,
    NoPermissionComponent,
    NoSubscriptionComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PagesModule { }
