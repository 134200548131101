import { Injectable } from '@angular/core';
import { Subscription, ReplaySubject, Observable, timer, map, take, tap, merge, async, from, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _location: ReplaySubject<any> = new ReplaySubject<any>(1);

  get location$(): Observable<any> {
    return this._location.asObservable();
  }

  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        this._location.next({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      }, (error) => {
        reject(null)
      });
    })
  }
}

