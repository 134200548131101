/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatchMediaService } from '../../@spark/services/match-media.service';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SparkNavigationService } from 'src/@spark/components/navigation/navigation.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { UserService } from '../core/user/user.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from '../core/common-service/utility.service';
import { TailwindService } from 'src/@spark/services/tailwind.service';
import { LayoutService } from './layout.service';
import { NotificationService } from '../core/messaging/notification.service';
import { MessagingService } from '../core/messaging/messaging.service';
import { MenuLayoutService } from './layouts/menu-layout/menu-layout.service';
import { HttpService } from '../core/common-service/http.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  config;
  layout;
  showBottomNav = true;
  showShadow = false;
  isPartner = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  scheme: any;

  constructor(
    private _router: Router,
    private _sparkConfigService: SparkConfigService,
    private _activatedRoute: ActivatedRoute,
    private _messagingService: MessagingService,
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer,
    public dialog: MatDialog) {

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.scheme = config.scheme;
        this.layout = config.layout;
        this._updateScheme();
        this._updateLayout()
      });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._updateLayout();
      }
    })

    // Permission broadcast

    var bc = new BroadcastChannel('fcm_channel');

    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        notificationPerm.onchange = async () => {
          if (notificationPerm.state == 'granted') {
            this._messagingService.token$.then((val) => {
              bc.postMessage(val); /* send */
            }).catch((error) => {
              return Promise.resolve(null)
            })
          }
        };
      });
    }
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
      this.overlayContainer.getContainerElement().classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
      this.overlayContainer.getContainerElement().classList.remove(this.scheme);
    }
  }

  private _updateLayout(): void {
    if (
      this.config.layout == 'empty' ||
      this.config.layout == 'default') {
      let route = this._activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.layout = this.config.layout;
      const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout'));
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.layout = layoutFromQueryParam;
        }
      }

      // 3. Iterate through the paths and change the layout as we find
      // a config for it.
      //
      // The reason we do this is that there might be empty grouping
      // paths or componentless routes along the path. Because of that,
      // we cannot just assume that the layout configuration will be
      // in the last path's config or in the first path's config.
      //
      // So, we get all the paths that matched starting from root all
      // the way to the current activated route, walk through them one
      // by one and change the layout as we find the layout config. This
      // way, layout configuration can live anywhere within the path and
      // we won't miss it.
      //
      // Also, this will allow overriding the layout in any time so we
      // can have different layouts for different routes.

      const paths = route.pathFromRoot;
      paths.forEach((path) => {

        // Check if there is a 'layout' data
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
          // Set the layout
          this.layout = path.routeConfig.data['layout'];
        }

        // Check bottom nav
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.hasOwnProperty('showBottomNav')) {
          this.showBottomNav = path.routeConfig.data['showBottomNav'];
        }
        else {
          this.showBottomNav = true
        }

        // Check isPartner
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.hasOwnProperty('isPartner')) {
          this.isPartner = path.routeConfig.data['isPartner'];
        }
        else {
          this.isPartner = false
        }

        // Check shadow
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.hasOwnProperty('showShadow')) {
          this.showShadow = path.routeConfig.data['showShadow'];
        }
        else {
          this.showShadow = false
        }
      });
    }
    else {
      this.layout = 'default';
    }
  }
}
