import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuLayoutService } from 'src/app/layout/layouts/menu-layout/menu-layout.service';
import { MenuCardService } from '../menu-card.service';

@Component({
  selector: 'app-menu-card-home',
  templateUrl: './menu-card-home.component.html',
  styleUrls: ['./menu-card-home.component.scss']
})
export class MenuCardHomeComponent implements OnInit {
  hotelData;

  categories: any[] = []
  menuTypes: any[] = [];

  hasError: boolean;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _menuCardService: MenuCardService,
    private _activeRoute: ActivatedRoute,
    private menuLayoutService: MenuLayoutService,
    private splashScreenStateService: SplashScreenStateService,
    private ngxUiService: NgxUiLoaderService,
    @Inject('HOTELDATA') private apiURL: string
  ) {

    // Getting hotel data
    menuLayoutService.hotelData.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(data => {
        this.hotelData = data
      }, (error) => {
        this.hasError = true
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Get menu types
    this.ngxUiService.startLoader('loader-02')
    this.menuLayoutService.getMenuTypes().then((data: any) => {
      this.menuTypes = data
      setTimeout(() => {
        this.splashScreenStateService.stop()
      }, 2000);
    }).finally(() => {
      this.ngxUiService.stopLoader('loader-02')
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
