import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AdminAuthService } from '../admin-auth/admin-auth.service';
import { UtilityService } from './utility.service';
import { of, Subject, takeUntil, map, takeWhile, tap, Observable, expand, delay, throwError, catchError, skip } from 'rxjs';
import { AdminLayoutService } from 'src/app/layout/layouts/admin/admin-layout.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.APIUrl;
  mainUrl = environment.APIMainUrl;
  config;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  hotelData: any;

  get ownerAcessToken(): string {
    let key = this._utility.encrypt('businessAccessToken')
    return sessionStorage.getItem(key) ?? '';
  }

  get userAccessToken(): string {
    let key = this._utility.encrypt('userAccessToken')
    return sessionStorage.getItem(key) ?? '';
  }


  get userTenantId(): string {
    let key = this._utility.encrypt('personal_tenant_id')
    return sessionStorage.getItem(key) ?? '';
  }
  get ownerTenantId(): string {
    let key = this._utility.encrypt('business_tenant_id')
    return sessionStorage.getItem(key) ?? '';
  }

  constructor(private _http: HttpClient,
    private _utility: UtilityService,
    private _sparkConfigService: SparkConfigService,) {
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config
      });

  }

  getPartner(path, tenantRequired = true) {
    let url = this.baseUrl + this.config.partnerLanguage + "/external/"
    if (tenantRequired) url += this.ownerTenantId + "/"
    let tokenData = JSON.parse(this._utility.decryptAES(this.ownerAcessToken))
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData.token)
    }
    return this._http.get(url + path, header);
  }

  getPersonal(path, tenantRequired = true) {
    let url = this.baseUrl + this.config.userLanguage + "/external/";
    if (tenantRequired) url += this.userTenantId + "/user/"
    let tokenData = JSON.parse(this._utility.decryptAES(this.userAccessToken))
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData.token)
    }
    return this._http.get(url + path, header);
  }

  getPublic(path, portal) {
    let url = this.baseUrl + (portal == 'user' ? (this.config.userLanguage ?? 'en-in') : (this.config.partnerLanguage ?? 'en-in')) + "/"
    return this._http.get(url + path);
  }

  postPublic(path, data, portal) {
    let url = this.baseUrl + (portal == 'user' ? (this.config.userLanguage ?? 'en-in') : (this.config.partnerLanguage ?? 'en-in')) + "/"
    return this._http.post(url + path, data);
  }

  postPartner(path, data, tenantRequired = true) {
    let url = this.baseUrl + (this.config.partnerLanguage ?? 'en-in') + "/external/"
    if (tenantRequired) url += this.ownerTenantId + "/"
    let tokenData = this._utility.decryptAES(this.ownerAcessToken) ? JSON.parse(this._utility.decryptAES(this.ownerAcessToken)) : ''
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData?.token)
    }
    return this._http.post(url + path, data, header);
  }

  postUser(path, data, tenantRequired = true) {
    let url = this.baseUrl + this.config.userLanguage + "/" + "external/"
    if (tenantRequired) url += this.userTenantId + "/user/"
    let tokenData = this._utility.decryptAES(this.userAccessToken) ? JSON.parse(this._utility.decryptAES(this.userAccessToken)) : ''
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData?.token)
    }
    return this._http.post(url + path, data, header);
  }

  postDownloadBusiness(path, data) {
    // return this._http.get(this.baseUrl + path, { responseType: 'blob' });
    let url = this.baseUrl + (this.config.partnerLanguage ?? 'en-in') + "/external/"
    url += this.ownerTenantId + "/"
    let tokenData = JSON.parse(this._utility.decryptAES(this.ownerAcessToken))
    return this._http.post(url + path, data, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData.token),
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((res: any) => {
      if (res.body) {
        if (res.headers.get('Content-Type') == "application/json") {
          return null;
        }
        else {
          let data = {
            data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('File-Name')
          }
          return data;
        }
      }
      return null
    }
    ))
  }

  getDownloadBusiness(path) {
    let url = this.baseUrl + (this.config.partnerLanguage ?? 'en-in') + "/external/"
    url += this.ownerTenantId + "/"
    let tokenData = JSON.parse(this._utility.decryptAES(this.ownerAcessToken))
    return this._http.get(url + path, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData.token),
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((res: any) => {
      if (res.body) {
        if (res.headers.get('Content-Type') == "application/json") {
          return null;
        }
        else {
          let data = {
            data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: ''
          }
          return data;
        }
      }
      return null
    }))
  }

  getDownloadPersonal(path) {
    let url = this.baseUrl + (this.config.userLanguage ?? 'en-in') + "/external/"
    url += this.userTenantId + "/user/"
    let tokenData = JSON.parse(this._utility.decryptAES(this.userAccessToken))
    return this._http.get(url + path, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData.token),
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((res: any) => {
      if (res.body) {
        if (res.headers.get('Content-Type') == "application/json") {
          return null;
        }
        else {
          let data = {
            data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('File-Name')
          }
          return data;
        }
      }
      return null
    }))
  }

  logoutUser(path, data) {
    let url = this.baseUrl + this.config.userLanguage + "/external/"
    let tokenData = this._utility.decryptAES(this.userAccessToken) ? JSON.parse(this._utility.decryptAES(this.userAccessToken)) : ''
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + tokenData?.token)
    }
    return this._http.post(url + path, data, header);
  }

  post(path, data, skip?) {
    return this._http.post(this.baseUrl + "en-in/" + path, data, { headers: skip ? { 'skip': 'true' } : {} });
  }

  postExternal(path, data, skip?) {
    let headers = new HttpHeaders();
    headers = headers.append('external', 'true');
    if (skip)
      headers = headers.append('skip', 'true');
    return this._http.post(this.mainUrl + "en-in/" + path, data,
      { headers: headers });
  }

  getExternal(path, skip?, token?) {
    let headers = new HttpHeaders();
    headers = headers.append('external', 'true');
    if (skip)
      headers = headers.append('skip', 'true');
    if (token)
      headers = headers.append('Authorization', 'Bearer ' + token);
    return this._http.get(this.mainUrl + "en-in/" + path,
      { headers: headers });
  }

  // External api
  getExternalPersonal(path) {
    let key = this._utility.encrypt('userAccessToken')
    let localToken = this._utility.getLocalSession(key)
    let tokenData = JSON.parse(this._utility.getProfileSecret(localToken.username + "_" + "profile_token"))
    if (tokenData)
      return this._http.get(this.mainUrl + (this.config.userLanguage ?? 'en-in') + "/" + path,
        { headers: { 'Authorization': 'Bearer ' + tokenData.token, 'external': 'user' } });
    else
      return of(false)
  }

  getExternalBusiness(path) {
    let key = this._utility.encrypt('businessAccessToken')
    let localToken = this._utility.getLocalSession(key)
    let tokenData = JSON.parse(this._utility.getProfileSecret(localToken.username + "_" + "profile_token"))
    return this._http.get(this.mainUrl + (this.config.partnerLanguage ?? 'en-in') + "/" + path,
      { headers: { 'Authorization': 'Bearer ' + tokenData.token, 'external': 'business' } });
  }

  getExternalPublic(path, portal) {
    let url = this.mainUrl + (portal == 'user' ? (this.config.userLanguage ?? 'en-in') : (this.config.partnerLanguage ?? 'en-in')) + "/"
    return this._http.get(url + path);
  }
}
