<ng-container *transloco="let t">
  <mat-sidenav-container>
    <mat-sidenav mode="over" class="px-6 py-4 w-64 bg-gray-900" #drawer position="end">
      <div class="flex justify-end w-full">
        <button mat-icon-button (click)="drawer.toggle();"
          class="mat-focus-indicator ml-auto mat-icon-button mat-button-base">
          <mat-icon role="img" class="text-white" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="x"
            data-mat-icon-namespace="heroicons_outline">
            close
          </mat-icon>
        </button>
      </div>
      <ul class="navbar-nav side-nav flex gap-2">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/']" (click)="isMenuShow=false;drawer.close();"
            [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="'active'">
            {{t('landing.title.home')}}
          </a>
        </li>
        <ng-container>
          <li class="nav-item partner-link">
            <a class="nav-link" [routerLink]="['/become-a-partner']" (click)="isMenuShow=false;drawer.close();"
              [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="'active'">
              {{t('landing.title.become_a_partner')}}
            </a>
          </li>
          <li class="nav-item navbar-btn-resp flex items-center">
            <a routerLink="/become-a-partner" class="primary-btn">{{t('landing.title.become_a_partner')}}</a>
          </li>
        </ng-container>
      </ul>
    </mat-sidenav>
    <!-- Body -->
    <!--// Header Start //-->
    <header class="header fixed-top w-full" (scroll)="onWindowScroll();" id="header">
      <div id="nav-menu-wrap">
        <div class="m-auto">
          <nav class="navbar navbar-expand-lg p-0 flex justify-between items-center gap-4">
            <!-- Logo -->
            <div class="flex justify-between flex-1 items-center">
              <a class="navbar-brand" href="/">
                <div>
                  <img src="assets/logo/orderplzz-full-logo.png" alt="Logo White"
                    class="logo img-fluid logo-transparent">
                  <img src="assets/logo/orderplzz-full-logo.png" alt="Logo White" class="img-fluid logo-normal">
                </div>
              </a>
            </div>
            <!-- Mobile nav -->
            <div class="items-center nav-btn mt-1 hidden">
              <button class="nav-link my-account-menu" *ngIf="isPartner" mat-button [matMenuTriggerFor]="menu">
                <ng-container *ngTemplateOutlet="menuButton;context:{data:partnerData}"></ng-container>
              </button>
              <button class="nav-link my-account-menu" *ngIf="!isPartner" [matMenuTriggerFor]="userMenu" mat-button>
                <ng-container *ngTemplateOutlet="menuButton;context:{data:userData}"></ng-container>
              </button>
              <button class="navbar-toggler" (click)="drawer.open()" type="button" data-toggle="collapse"
                [attr.aria-expanded]="isMenuShow" aria-label="Toggle navigation">
                <span class="togler-icon-inner">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <span class="line-3"></span>
                </span>
              </button>
            </div>

            <!-- Desktop nav -->
            <div class="collapse navbar-collapse desktop-menu main-menu justify-end mr-3" [class.show]="isMenuShow">
              <ul class="navbar-nav flex items-center">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/']" (click)="isMenuShow=false"
                    [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="'active'">
                    {{t('landing.title.home')}}
                  </a>
                </li>

                <li class="nav-item flex items-center">
                  <a class="nav-link" *ngIf="isPartner && partnerData" [matMenuTriggerFor]="menu">
                    <ng-container *ngTemplateOutlet="menuButton;context:{data:partnerData}"></ng-container>
                  </a>
                  <a class="nav-link" *ngIf="(!isPartner) && userData" [matMenuTriggerFor]="userMenu">
                    <ng-container *ngTemplateOutlet="menuButton;context:{data:userData}"></ng-container>
                  </a>

                  <ng-container *ngIf="(!isPartner && !userData) || (isPartner && !partnerData)">
                    <a class="nav-link" (click)="isMenuShow=false;goToMyAccount()">
                      {{t('landing.title.login_register')}}
                    </a>
                  </ng-container>
                </li>

                <ng-container *ngIf="!isPartner">
                  <li class="nav-item navbar-btn-resp flex items-center">
                    <a routerLink="/become-a-partner" class="primary-btn">{{t('landing.title.become_a_partner')}}</a>
                  </li>
                </ng-container>
              </ul>
              <ng-template #menuButton let-data="data">
                <div class="flex items-center gap-2">
                  <ng-container>
                    <img *ngIf="data?.profile_photo_path" class="rounded-full icon-size-7"
                      src="{{ data?.profile_photo_path ? data?.profile_photo_path : 'assets/placeholder/user.jpg'}}">
                    <mat-icon class="icon-size-7" *ngIf="!data?.profile_photo_path">person
                    </mat-icon>
                    <p class="block md:hidden">{{ data?.first_name }}</p>
                  </ng-container>
                </div>
              </ng-template>

              <mat-menu #menu="matMenu">
                <span>
                  <button mat-menu-item (click)="goToMyAccount()">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">account_circle
                    </mat-icon>{{t('user_menu.title.manage_profile')}}
                  </button>
                  <button mat-menu-item (click)="goToDashboard()">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">dashboard
                    </mat-icon>{{t('landing.title.go_to_dashboard')}}
                  </button>
                </span>
              </mat-menu>

              <mat-menu #userMenu="matMenu">
                <span>
                  <button mat-menu-item (click)="goToMyAccount()">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">account_circle
                    </mat-icon>{{t('user_menu.title.manage_profile')}}
                  </button>
                  <button mat-menu-item [routerLink]="'/my-orders'">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">list_alt
                    </mat-icon>{{t('user_menu.title.my_orders')}}
                  </button>
                </span>
              </mat-menu>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!--// Header End  //-->

    <router-outlet></router-outlet>

    <!--// Footer Start //-->
    <footer class="footer">
      <div class="footer-top">
        <div class="footer-container m-auto">
          <div class="flex flex-row gap-4 sm:flex-col">
            <div class="flex-1 footer-widget-resp">
              <div class="footer-widget">
                <h6 class="footer-title">{{t('landing.about_us.title')}}</h6>
                <p class="footer-desc">
                  {{t('landing.footer.about_us')}}
                </p>
                <!-- <div class="footer-social-links">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-youtube"></i>
              </a>
            </div> -->
              </div>
            </div>
            <div class="flex-1 footer-widget-resp">
              <div class="footer-widget footer-widget-pl">
                <h6 class="footer-title">{{t('landing.footer.usefull_links')}}</h6>
                <ul class="footer-links">
                  <li>
                    <a href="/">{{t('landing.title.home')}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/become-a-partner']">{{t('landing.title.become_a_partner')}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex-1 footer-widget-resp">
              <div class="footer-widget">
                <h6 class="footer-title">{{t('landing.footer.contact_info')}}</h6>
                <div class="footer-contact-info-wrap">
                  <ul class="footer-contact-info-list">
                    <!-- <li>
                  <i class="fa fa-map-marker"></i>
                  <p>8595 Marconi Rd. Phillipsburg, NJ 08865</p>
                </li> -->
                    <li class="items-center">
                      <i class="fa fa-envelope"></i>
                      <div class="text">
                        <a href="mailto:contact@thesparktech.com">contact@thesparktech.com</a>
                      </div>
                    </li>
                  </ul>
                  <div class="flex sm:flex-col items-center sm:items-start mt-3 gap-3">
                    <p class="text-white">{{t('landing.footer.connect_us_on')}}: </p>
                    <ul class="flex gap-3 items-center">
                      <li>
                        <a target="_blank" href="https://www.facebook.com/orderplzz">
                          <img src="assets/icons/social/facebook.png" class="w-5">
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.instagram.com/orderplzz_/">
                          <img src="assets/icons/social/instagram.png" class="w-4">
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://x.com/orderplzz?s=20">
                          <img src="assets/icons/social/x.png" class="w-4">
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.threads.net/@orderplzz_">
                          <img src="assets/icons/social/threads.png" class="w-4">
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.linkedin.com/company/101520554">
                          <img src="assets/icons/social/linkedin.png" class="w-6">
                        </a>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex-1 footer-widget-resp">
              <div class="footer-widget">
                <h6 class="footer-title">Connect us on</h6>
                <div class="footer-contact-info-wrap">

                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="copyright text-center">
        <div class="container">
          <p class="copyright-text">{{t('landing.footer.copyright_year',{year:currentDate | date:'yyyy'})}} <br>
            {{t('landing.footer.powered_by')}} <a href="{{orgURL}}" target="_blank">{{t('spark_technologies')}}</a> </p>
          <p class="copyright-text mt-2">{{t('landing.footer.made_with_in_india')}}</p>
        </div>
        <ul class="copyright-links mt-4 text-sm">
          <li><a href="{{orgURL}}/policy/privacy-policy" target="blank">{{t('privacy_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/cookie-policy" target="blank">{{t('cookie_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/refund-policy" target="blank">{{t('refund_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/disclaimer" target="blank">{{t('disclaimer')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-use" target="blank">{{t('t_of_use')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-and-conditions" target="blank">{{t('t_c')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/trademarks" target="blank">{{t('trademarks')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-services" target="blank">{{t('t_of_services')}}</a></li>
        </ul>
        <p class="text-gray-500 mt-3 text-xs">{{t('illustrations_by')}} <a target="_blank"
            href="https://www.freepik.com/">Freepik</a>
        </p>
      </div>
    </footer>
    <!--// Footer End //-->


    <a (click)="scrollToTop()" class="scroll-top-btn shadow-2xl">
      <i class="fa fa-arrow-up"></i>
    </a>
  </mat-sidenav-container>

</ng-container>