import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SparkModule } from 'src/@spark/spark.module';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { RouteSplashScreenStateService } from 'src/@spark/services/route-splash-screen-state.service';
import { AdminAuthService } from 'src/app/core/admin-auth/admin-auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';
import { CheckMenuTypeModalComponent } from './check-menu-type-modal/check-menu-type-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TourModalComponent } from './tour-modal/tour-modal.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    AllNotificationsComponent,
    CheckMenuTypeModalComponent,
    ShareModalComponent,
    TourModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RouterModule,
    MatSidenavModule,
    MatTooltipModule,
    MatBadgeModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    SparkModule,
    SharedModule,
    MatCheckboxModule,
    TranslocoRootModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  exports: [
    AdminLayoutComponent
  ],
  providers: [
    RouteSplashScreenStateService,
    AdminAuthService,
  ]
})
export class AdminModule { }
