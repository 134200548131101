<div class="flex flex-col h-full items-center justify-center" [loaderId]="'loader-02'" ngxUiLoaderBlurred [blur]="5"
  *transloco="let t">
  <ng-container>
    <div class="-mt-40">
      <!-- Hotel info-->
      <img class="rounded-full w-40 m-auto"
        src="{{hotelData?.business_logo?hotelData?.business_logo:'assets/placeholder/no-image.png'}}" alt="">
      <h3 class="text-center text-3xl mt-4">{{t('welcome_to')}} {{hotelData?.business_name}}
      </h3>
      <!-- Menu types -->
      <div class="flex flex-col gap-4 m-auto items-center justify-center mt-6" *ngIf="menuTypes">
        <p class="text-base text-center secondary-text">{{t('user_menu.title.choose_menu_type')}}</p>
        <div class="flex sm:flex-col gap-6 items-center">
          <ng-container *ngFor="let menuType of menuTypes; let i=index">
            <div matRipple routerLink="{{menuType?.food_menu_type.menu_type_slug}}"
              class="services-item relative rounded-lg cursor-pointer text-center flex flex-col px-6 py-4 shadow-md w-36">
              <img class="m-auto" src="assets/icons/{{menuType.food_menu_type.menu_type_slug}}.png" alt="">
              <h6 class="mt-3">{{menuType.food_menu_type.menu_type}}</h6>
            </div>
          </ng-container>
        </div>
      </div>
      <p *ngIf="!menuTypes" class="text-center mt-4 secondary-text">{{t('user_menu.message.no_menu_available')}}</p>
    </div>
  </ng-container>
</div>
<ngx-ui-loader [loaderId]="'loader-02'"></ngx-ui-loader>