<div class="flex flex-col h-full menu-background" *transloco="let t">
  <!-- Back button-->
  <div class="opz-back-button" [routerLink]="hotelData?('/menu/'+partnerName+'/'+menuTypeSlug):'/'"
    queryParamsHandling="merge">
    <img class="w-8 cursor-pointer" src="assets/icons/BackButton.png">
  </div>

  <div class="opz-page-title">
    <h3>{{t('user_menu.title.my_orders')}}</h3>
  </div>

  <div class="clear-both"></div>

  <div class="hotel-box flex-1 w-full">
    <svg width="100%" viewBox="0 0 390 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M114.506 0H40C17.9086 0 0 17.9086 0 40V657C0 679.091 17.9086 697 40 697H350C372.091 697 390 679.091 390 657V40C390 17.9086 372.091 0 350 0H277.995C272.816 0 267.687 1.00561 262.891 2.961L244.307 10.539C239.512 12.4944 234.383 13.5 229.204 13.5H162.93C157.656 13.5 152.434 12.4569 147.564 10.4306L129.872 3.06938C125.003 1.04314 119.78 0 114.506 0Z"
        fill="var(--spark-theme-black)" />
      <rect x="164.845" y="2" width="59.3041" height="4" rx="2" fill="var(--spark-theme-black)" />
    </svg>
    <div class="opz-content">
      <div class="opz-box">

        <div class="flex justify-end">
          <button mat-button class="add-round-button" (click)="fetchOrders(0)">
            <mat-icon class="mr-2">refresh</mat-icon>{{t('action.refresh')}}
          </button>
        </div>

        <h4 class="mb-4" *ngIf="hotelData">
          {{t('user_menu.title.orders_from_hotel',{hotel:hotelData?.business_name})}}</h4>
        <!-- Orrder table
        <table mat-table class="hidden" matSort [dataSource]="dataSource">
          <ng-container matColumnDef="order_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{t('message.sr_no')}}</th>
            <td mat-cell *matCellDef="let order;let i=index">{{i+1 +
              (pagination.page *
              pagination.size)}}</td>
          </ng-container>

          <ng-container matColumnDef="partner_name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner name
            </th>
            <td mat-cell *matCellDef="let order">{{order.partner_business.business_name}}</td>
          </ng-container>

          <ng-container matColumnDef="table_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Table name
            </th>
            <td mat-cell *matCellDef="let order">{{order.table_name}}</td>
          </ng-container>

          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total amount </th>
            <td mat-cell *matCellDef="let order">
              {{hotelData.business_branch?.country.currency_symbol}}{{order.total_amount}}
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_mode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Paymeny by
            </th>
            <td mat-cell *matCellDef="let order">{{order.payment_mode}}</td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Order date & time
            </th>
            <td mat-cell *matCellDef="let order">{{order.created_at}}</td>
          </ng-container>

          <ng-container matColumnDef="order_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let order"> {{order.order_status.status_name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> View </th>
            <td mat-cell *matCellDef="let order">
              <mat-icon routerLink="{{order.order_key}}" class="text-gray-500 cursor-pointer">visibility
              </mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="orderColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: orderColumns;"></tr>
        </table>-->

        <div class="mt-3 order-list grid grid-cols-1 w-full md:grid-cols-1 gap-4">
          <mat-card *ngFor="let item of dataSource;let i=index" class="order-card py-3 px-4 bg-gray-800">
            <div class="header flex flex-row justify-between">
              <h5 class="flex flex-row items-center gap-2">{{item.receipt_id}} </h5>
              <h5>
                {{item.partner_business?.business_branch?.country?.currency_symbol}}{{item.total_amount.toLocaleString()}}
              </h5>
            </div>

            <mat-divider class="my-3 text-gray-500"></mat-divider>
            <h6 class="text-secondary text-gray-400">{{t('order.title.order_type')}} : <span
                class="text-gray-100">{{item.table_number?
                t('user_menu.title.dine_in')+' ('+ t('user_menu.title.table_no')+'
                '+item.table_number+')':t('user_menu.title.take_away')}}</span>
            </h6>
            <h6 class="text-secondary text-gray-400">{{t('user_menu.title.ordered_at')}} <span
                class="text-gray-100">{{item.ordered_at}}</span>
            </h6>
            <span *ngIf="item.order_status.status_slug!='cancelled' && item.order_status.status_slug!='completed'"
              class="text-secondary mt-1 text-blue-600 cursor-pointer" queryParamsHandling="merge"
              [queryParams]="!hotelData?{fromLanding:true}:{fromLanding:null}"
              [routerLink]="['/menu/' +
              (!hotelData?item.partner_business?.partner_name:partnerName) + '/' +
              (!hotelData?item.partner_food_menu_type?.food_menu_type?.menu_type_slug: menuTypeSlug) + '/track-order/'+item.order_key+'/my-orders']">
              {{t('user_menu.title.check_status')}}
            </span>
            <span *ngIf="item.order_status.status_slug=='cancelled' && item.payment_type==1"
              class="text-secondary mt-1 text-blue-600 cursor-pointer" queryParamsHandling="merge"
              [queryParams]="!hotelData?{fromLanding:true}:{fromLanding:null}"
              [routerLink]="['/menu/' +
              (!hotelData?item.partner_business?.partner_name:partnerName) + '/' +
              (!hotelData?item.partner_food_menu_type?.food_menu_type?.menu_type_slug: menuTypeSlug) + '/track-refund/'+item.order_key]">
              {{t('user_menu.title.check_refund_status')}}
            </span>

            <mat-divider class="my-3 text-gray-500"></mat-divider>

            <div class="flex justify-between">
              <h6 class="text-secondary text-gray-100 flex items-center gap-2">
                <div class="rounded-full w-2 h-2 " [style.background-color]="item.order_status.color_code"></div>
                {{item.order_status.status_name}}
              </h6>
              <p [routerLink]="
              ['/menu/' + (!hotelData?item.partner_business?.partner_name : partnerName) + '/'
              + (!hotelData?item.partner_food_menu_type?.food_menu_type?.menu_type_slug : menuTypeSlug)
              + '/order-details/' + item.order_key]" [queryParams]="!hotelData?{fromLanding:true}:{fromLanding:null}"
                queryParamsHandling="merge" class="text-base cursor-pointer text-blue-600">
                {{t('user_menu.title.show_details')}}<mat-icon class="icon-size-6 ">chevron_right</mat-icon>
              </p>
            </div>
          </mat-card>
        </div>

        <!-- No records found -->
        <div *ngIf="!hasError && !isLoading && dataSource.length<=0" class="table-message">
          <mat-icon class="text-yellow-500">warning_amber</mat-icon>
          <span>{{t('user_menu.message.no_orders_found')}}</span>
        </div>

        <!-- Error occured -->
        <div *ngIf="hasError && !isLoading" class="table-message">
          <mat-icon class="text-red-600">error_outline</mat-icon>
          <span>{{t('error.error_occured')}}</span>
        </div>

        <!-- Loading data -->
        <div *ngIf="!hasError && isLoading" class="table-message">
          <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"
            style="vertical-align:middle; display: inline-block;" class="inline-block text-green-500">
          </mat-progress-spinner> <span class="ml-3">{{t('user_menu.message.loading_orders')}}</span>
        </div>
        <!-- Pagination -->
        <mat-paginator [hidden]="isLoading" class="mt-3 rounded-xl" #paginator (page)="pageChanged($event)"
          hidePageSize="true" [length]="pagination.length" [pageIndex]="pagination.page" showFirstLastButtons
          [pageSize]="pagination.size" [pageSizeOptions]="[10, 25,50,75, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>