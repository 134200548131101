import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry
  ) {
    // Register icon sets
    this._matIconRegistry.addSvgIcon(`right-arrow`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/rightarrow.svg'));
    this._matIconRegistry.addSvgIcon(`icon-profile`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/profile.svg'));
    this._matIconRegistry.addSvgIcon(`icon-cart`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/cart.svg'));
    this._matIconRegistry.addSvgIcon(`icon-add`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/add.svg'));
    this._matIconRegistry.addSvgIcon(`icon-add-2`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/add-2.svg'));
    this._matIconRegistry.addSvgIcon(`icon-filter`, this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/filter.svg'));
  }
}
