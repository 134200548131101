import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse,
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, NEVER, Observable, Subject, empty, from, throwError } from 'rxjs';
import { catchError, finalize, map, retry, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { UserService } from '../user/user.service';
import Swal from 'sweetalert2';
import { HttpService } from './http.service';
@Injectable({ providedIn: 'root' })
export class HttpIntercept implements HttpInterceptor {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  personalKey = this._utility.encrypt("personal")
  businessKey = this._utility.encrypt("business")
  userData: any;

  isTokenRefreshing = false;

  constructor(
    private _toastr: ToastrService,
    private _utility: UtilityService,
    private _router: Router,
    private _userService: UserService,
    private _httpService: HttpService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.isTokenRefreshing = false
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })
  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = encodeURIComponent(window.location.href);
    return next.handle(request)
      .pipe(
        timeout(60000),
        map((event: HttpEvent<any>) => {

          if (request.headers.get('skip')) {
            return event;
          }

          if (event instanceof HttpResponse && event.body.success == 0) {
            if (!request.headers.get('external')) {
              switch (event.body.status_code) {
                case 401:
                  throw new HttpErrorResponse(
                    {
                      status: 401
                    }
                  );
                  break;
                case 452: // BUSINESS NO PERMISSION
                case 453: // PERSONAL NO PERMISSION
                  //sessionStorage.clear()
                  this.redirectTo('/no-permission/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                  break;
                case 500: // INTERNAL_SERVER_ERROR
                  this._toastr.error(event.body.message);
                  this.redirectTo('/error/' + event.body.status_code + '?error=Internal Server Error&ref=' + url)
                  break;
                case 601: // USERNAME_NOT_VERIFIED
                case 604: // USER_NOT_VERIFIED
                case 605: // USER_BLOCKED
                case 606: // EMAIL_ALREADY_VERIFIED
                case 607: // EMAIL_NOT_VERIFIED
                case 609: // NO ACCESS
                case 610: // NOT SUBSCRIBED
                  this._toastr.error(event.body.message);
                  this.redirectTo('/no-subscription?error=' + event.body.message + '&ref=' + url)
                  break;
                case 615: // UPGRADE SUBSCRIPTION
                  this._toastr.error(event.body.message);
                  break;
                case 611: // INVALID URL
                  this._toastr.error(event.body.message);
                  this._router.navigateByUrl('/')
                  break;
                case 612: // BUSINESS BLOCKED
                  this._toastr.error(event.body.message);
                  this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                  break;
                case 613: // COMMINNG SOON
                case 614: // COME BACK
                  break;
                case 650: // SYSTEM_DOWN
                case 503: // SYSTEM_DOWN
                  this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                  break;
                case 200: // OK
                  return event;
                case 608: // VALIDATION_ERROR
                case 603: // OTP_RESEND_LIMIT_REACHED
                case 400: // REQUEST CAN'T BE COMPLETED
                case 653: // MEDIA OPERATION FAILED
                  this._toastr.error(event.body.message);
                  return event;
                default:
                  this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                  this._toastr.error('Unknown error');
                  throw new HttpErrorResponse({
                    error: event.body.message,
                    status: event.body.status_code
                  })
              }
            }
            else {
              switch (event.body.status_code) {
                case 401:
                  throw new HttpErrorResponse(
                    {
                      status: 401
                    }
                  );
                  break;
              }
            }
          }
          return event;
        }),

        catchError((error: HttpErrorResponse) => {
          if (error.name.toString() == 'TimeoutError') {
            this._toastr.warning('Request Time out!');
          }
          else {
            let message = 'Unknown error';
            switch (error.status) {
              case 404:
                this._toastr.error('Service not found');
                break;
              case 401:
                if (request.headers.get('external')) {
                  this.isTokenRefreshing = false;
                  return from(this.getExternalRefreshToken(request.headers.get('external'))).
                    pipe(
                      switchMap((res: any) => {
                        if (res.success == 1) {
                          return from(this._httpService.getExternal('external/common/user/token/expiresAt', true, res.data['api_token']))
                            .pipe(switchMap((expiry: any) => {
                              this.setExternalRefreshToken(res.data['api_token'], res.data['refresh_token'], request.headers.get('external'), expiry)
                              request = request.clone({
                                setHeaders: {
                                  Authorization: 'Bearer ' + res.data['api_token'],
                                },
                              });
                              return next.handle(request);
                            }))
                        }
                        else {
                          this._toastr.error('Session expired');
                          //this._utility.clearSession()
                          let session = this._utility.getLocalSession(
                            request.headers.get('external') == 'business' ? this._utility.encrypt('businessAccessToken') : this._utility.encrypt('userAccessToken')
                          )
                          this._utility.removeSession(session.username, request.headers.get('external') == 'business' ? 1 : 2);
                          this._utility.goToLogin(window.location.href, 'self')
                        }
                        return throwError(error);
                      },),
                      catchError((err) => {
                        console.log(err);
                        return throwError(err);
                      }),
                      finalize(() => {
                        this.isTokenRefreshing = false
                      }),
                    )
                }
                else {
                  if (this.isTokenRefreshing) {
                    this.isTokenRefreshing = false;
                    return next.handle(request);
                  }
                  this.isTokenRefreshing = true;
                  return from(this.getRefreshToken()).pipe(
                    switchMap((res: any) => {
                      if (res.success == 1) {
                        this.setRefreshToken(res.data['api_token'], res.data['refresh_token'])
                        request = request.clone({
                          setHeaders: {
                            Authorization: 'Bearer ' + res.data['api_token'],
                          },
                        });
                        return next.handle(request);
                      }
                      else {
                        this._toastr.error('Session expired');
                        //this._utility.clearSession()
                        this._utility.removeSession(this.userData.user_login.username, this.userData.user_login.user_role_name.user_role_drc_key)
                        this._utility.goToLogin(window.location.href, 'self')
                      }
                      return throwError(error);
                    }),
                    catchError((err) => {
                      console.log(err);
                      return throwError(err);
                    }),
                    finalize(() => {
                      this.isTokenRefreshing = false
                    }));
                }

                break;
              case 429: // NO ACCESS
                this._toastr.error("Too many requests");
                break;
              case 500:
                console.log(error);
                this._toastr.error('Internal server error');
                break;
              default:
                this._toastr.error('Unknown error');
            }
          }
          this.redirectTo('/error/' + error.status + '?ref=' + url)
          console.log("Error while calling " + error.url)
          return throwError(error);
        })
      )
  }

  redirectTo(url) {
    const tree: UrlTree = this._router.parseUrl(window.location.pathname);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g?.segments;
    if ((s && s[0].path != 'error') || !s)
      this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true })
  }

  getRefreshToken() {
    let key = this.userData.user_login.user_role_name.user_role_drc_key == 1 ?
      this._utility.encrypt('businessAccessToken') :
      this._utility.encrypt('userAccessToken')
    console.log(this.userData);

    let tokenData = JSON.parse(this._utility.decryptAES(sessionStorage.getItem(key)))
    return this._httpService.post('external/auth/refreshToken', {
      'refresh_token': tokenData.refreshToken,
      'username': tokenData.username
    }, true)
  }

  setRefreshToken(token, refreshToken) {
    let key = this.userData.user_login.user_role_name.user_role_drc_key == 1 ?
      this._utility.encrypt('businessAccessToken') :
      this._utility.encrypt('userAccessToken')
    let tokenData = JSON.parse(this._utility.decryptAES(sessionStorage.getItem(key)))
    let modifiedToken = tokenData;
    modifiedToken.token = token;
    modifiedToken.refreshToken = refreshToken;
    sessionStorage.setItem(key, this._utility.encryptAES(JSON.stringify(modifiedToken)).toString())
  }

  getExternalRefreshToken(type) {
    let key = type == 'business' ?
      this._utility.encrypt('businessAccessToken') :
      this._utility.encrypt('userAccessToken')
    let localToken = this._utility.getLocalSession(key)
    let tokenData = JSON.parse(this._utility.getProfileSecret(localToken.username + "_" + "profile_token"))
    return this._httpService.postExternal('external/auth/refreshToken', {
      'refresh_token': tokenData.refreshToken,
      'username': localToken.username
    }, true)
  }

  setExternalRefreshToken(token, refreshToken, type, expiry) {
    let key = type == 'business' ?
      this._utility.encrypt('businessAccessToken') :
      this._utility.encrypt('userAccessToken')
    let localToken = this._utility.getLocalSession(key)
    let tokenData = JSON.parse(this._utility.getProfileSecret(localToken.username + "_" + "profile_token"))
    let modifiedToken = tokenData;
    modifiedToken.token = token;
    modifiedToken.refreshToken = refreshToken;
    this._utility.setCookie(localToken.username + "_" + "profile_token", JSON.stringify(modifiedToken).toString())
    let creds = this._utility.getSecret("personalSecret")
    let index = creds.findIndex((obj) => obj.username == localToken.username);
    creds[index].expires_at = expiry.data;
    this._utility.setCookie('personalSecret', JSON.stringify(creds))
  }

}


export class DataNotFoundError extends Error {
  error = "Data not found";
}

export class UnknownError extends Error {
  error = "Something went wrong!";
}

