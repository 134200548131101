/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { DOCUMENT, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
import { delay, filter, map, Subject, take, takeUntil } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuCardService } from 'src/app/components/menu/menu-card.service';
import { environment } from 'src/environments/environment';
import { MenuLayoutService } from './menu-layout.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { UserService } from 'src/app/core/user/user.service';
import { UserAuthService } from 'src/app/core/user-auth/user-auth.service';
import moment from 'moment';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { MediaObserver } from '@angular/flex-layout';
import { trigger, transition, style, animate } from '@angular/animations';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import * as interval from 'worker-timers';
@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html',
  styleUrls: ['./menu-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('800ms ease-in', style({ transform: 'translateY(0%)' }))
      ])
    ])
  ]
})
export class MenuLayoutComponent implements OnInit {
  renderer: any;
  @Input() showBottomNav = true
  @Input() showShadow = false
  isActiveMatchOptions: any;
  showSplash = true
  hotelData;
  cartCount;
  hasError;
  scheme: any;
  userLanguage: any;
  config: any;
  userData: any;
  availableIn;
  closedIn;
  isAvailable;
  timer;
  personalKey = "personalSecret"
  personalRoleKey = this._utility.encrypt("personal")
  businessRoleKey = this._utility.encrypt("business")
  accountList: any[] = [];
  availablelanguages: any[] = [];
  languageCtrl: FormControl = new FormControl('');

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  menuTypeSlug: any;
  uid: any;
  tableNumber: any;

  /**
   * constructor
   */
  constructor(
    private menuService: MenuCardService,
    private menuLayoutService: MenuLayoutService,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: any,
    private _activeRoute: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    private _userService: UserService,
    private _sparkConfigService: SparkConfigService,
    private _authService: UserAuthService,
    private _utility: UtilityService,
    private splashScreenStateService: SplashScreenStateService,
    private commonApiService: CommonApiService,
    private _translocoService: TranslocoService,
    private _cartService: CartServiceService,
    public _mediaObserver: MediaObserver,
    public _messagingService: MessagingService,
    private _router: Router) {
    this.renderer = rendererFactory.createRenderer(null, null)
    // Subscribe to config
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config
        this.scheme = config.scheme;
        this.userLanguage = config.userLanguage ?? 'en-in'
      });

    // Splash screen service
    this.splashScreenStateService.subject.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(res => {
        this.showSplash = res;
      });

    // 1. Getting hotel data
    // 2. Getting cart count
    // 3. Setting interval for openng and closing hotel

    menuLayoutService.hotelData.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(data => {
        if (data) {
          try {
            this.hotelData = data
            //this.cartCount = this.getCartCount()
            if (this.timer)
              interval.clearInterval(this.timer)
            let businessHours = this.hotelData?.business_branch?.business_hours
            this.isAvailable = this.hotelData?.business_branch?.business_hours ?
              (this.hotelData.business_branch.business_hours.is_active ? true : false) : false
            if (businessHours && !this.hotelData.business_branch.business_hours.all_day) {
              for (let index = 0; index < businessHours?.working_hours.length; index++) {
                let timerExpire = false
                // Close //
                if (!this.hotelData.business_branch.business_hours.is_active) {
                  let open = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-us') + " " + businessHours?.working_hours[index].open)
                  let close = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-us') + " " + businessHours?.working_hours[index].close)
                  if (moment(open) > moment()) {
                    this.timer = interval.setInterval(() => {
                      if (!timerExpire) {
                        let diff = this.getDifference(moment(open))
                        if (diff.hours == 0 && diff.minute == 0 && diff.seconds == 0) {
                          this.updateAvailability(true)
                          timerExpire = true
                        }
                        else
                          this.availableIn = diff.hours + " hour " + diff.minute + " minutes" + " " + diff.seconds + " seconds";
                      }
                      else {
                        let diff = this.getDifference(moment(close))
                        if (diff.hours == 0 && diff.minute < 30 && diff.seconds >= 0) {
                          this.closedIn = diff.minute + " minutes" + " " + diff.seconds + " seconds";
                          if (diff.minute == 0 && diff.seconds == 0) {
                            this.updateAvailability(false)
                            interval.clearInterval(this.timer);
                          }
                        }
                      }
                    }, 1000)
                    break
                  }
                }
                // Open //
                else {
                  let close = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-us') + " " + businessHours?.working_hours[index].close)
                  if (moment() < moment(close)) {
                    this.timer = interval.setInterval(() => {
                      if (!timerExpire) {
                        let diff = this.getDifference(moment(close))
                        if (diff.hours == 0 && diff.minute < 30 && diff.seconds >= 0) {
                          this.closedIn = diff.minute + " minutes" + " " + diff.seconds + " seconds";
                          if (diff.minute == 0 && diff.seconds == 0) {
                            this.updateAvailability(false)
                            timerExpire = true
                          }
                        }
                      }
                      else {
                        if (index + 1 <= businessHours?.working_hours.length - 1) {
                          let open = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-us') + " " + businessHours?.working_hours[index + 1].open)
                          let diff = this.getDifference(moment(open))
                          this.availableIn = diff.hours + " hour " + diff.minute + " minutes" + " " + diff.seconds + " seconds";
                          if (diff.hours == 0 && diff.minute == 0 && diff.seconds == 0) {
                            this.updateAvailability(true)
                            interval.clearInterval(this.timer);
                          }
                        }
                      }
                    }, 1000)
                    break;
                  }
                }
              }
            }
          } catch (error) {
          }
        }
      }, (error) => {
        this.hasError = true
      })


    // Getting query params
    _activeRoute.queryParams.subscribe((param) => {
      this.uid = param['uid']
      if (this.uid) {
        let tableData = JSON.parse(atob(param['uid']))
        this.tableNumber = tableData.number
      }
    })

    var bc = new BroadcastChannel('fcm_channel');
    bc.onmessage = (message) => {
      if (Notification.permission == 'granted')
        this.menuLayoutService.registerUserFCM(message.data)
    }
  }

  getDifference(given) {
    var current = moment()
    var hours = given.diff(current, 'hours');
    current.add(hours, 'hours')
    var minute = given.diff(current, 'minute');
    current.add(minute, 'minute')
    var seconds = given.diff(current, 'second');
    return { hours, minute, seconds }
  }

  updateAvailability(setAvailable) {
    if (setAvailable) {
      this.isAvailable = true
      this.availableIn = null
      this.menuLayoutService.setAvailibility(true)
    }
    else {
      this.isAvailable = false
      this.closedIn = null
      this.menuLayoutService.setAvailibility(false)
    }
    window.location.reload();
  }

  hide(): void {
    this._document.body.classList.add('spark-splash-screen-hidden');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Get initial cart count
    //this.getCartCount()

    // Hide chat
    if (window.hasOwnProperty('Tawk_API') && (Object.keys(window['Tawk_API']).length != 0)) {
      window['Tawk_API'].hideWidget()
    }

    // Subscribe to cart count to update
    this._cartService.notification$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
        this.cartCount = res ? res.item_list.length : 0
      });

    // Get available languages
    this.commonApiService.getLanguages('user').then((data: any) => {
      this.availablelanguages = data
      let langArray: any[] = []
      this.availablelanguages.forEach((lang) => {
        langArray.push({
          id: lang.language_code,
          label: lang.language_name,
          icon: lang?.icon
        })
      })

      // Set all languages to transloco
      this._translocoService.setAvailableLangs(langArray)

      // Set active language
      let lang = this.availablelanguages.find((la) => la.language_code == this.userLanguage.toString())
      this._translocoService.setActiveLang(lang.language_code)
      this.languageCtrl.patchValue(lang)
    })

    // 1. Get user data
    // 2. Get account list from cookies
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
        this.cartCount = this.getCartCount()
        this.accountList = []
        let creds = this._utility.decryptAES(this._utility.getCookie(this.personalKey))
        let credData = JSON.parse(creds)
        for (let key in credData) {
          if ((this.userData.user_login.username.toString() != credData[key].username.toString())
            && credData[key].role == 2) {
            this.accountList.push({ ...credData[key] })
          }
        }
      })

    // Set routing options
    this.isActiveMatchOptions = {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'ignored'
    };

    // Updating theme and scheme
    this._updateTheme()
    this._updateScheme()
  }

  /**
   * After view load
   */
  ngAfterViewInit() {
    this._document.body.classList.add('spark-splash-screen-hidden');
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.menuLayoutService.hotelData.next(null)
  }

  /**
   * Encoded hotel name
   * @returns
   */
  encodedHotelName() {
    return encodeURIComponent(this.hotelData.partner_name)
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Applying settings
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setting brand theme of OrderPlzz!
   */
  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });
    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-brand`);
  }

  /**
   * Updating scheme (Only light theme)
   */
  private _updateScheme(): void {
    this._document.body.classList.remove('dark');
    this._document.body.classList.add('light');
    this.overlayContainer.getContainerElement().classList.remove('dark');
    this.overlayContainer.getContainerElement().classList.add('light');
  }

  /**
   * Setting language from dropdown
   * @param userLanguage
   */
  setLanguage(userLanguage): void {
    this._sparkConfigService.config = { userLanguage };
    localStorage.setItem('config', JSON.stringify(this.config))
    window.location.reload()
  }

  /**
   * Getting cart count
   * @returns Cart count
   */
  getCartCount() {
    let cart = this._cartService.getCartData((this.tableNumber ? this.tableNumber + '_' : '') + this.userData.user_login?.username + '_' + decodeURIComponent(this.hotelData?.partnerName) + '_' + this.hotelData?.menuType + '_cart');
    if (cart) {
      let cartData = cart
      return cartData.item_list.length > 0 ? cartData.item_list.length : ''
    }
    else
      return ""
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Auth methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Logout user
   */
  logout() {
    this._authService.signOut().finally(() => {
      this._utility.removeSession(this.userData.user_login.username, this.userData.user_login.user_role_name.user_role_drc_key)
      this._utility.goToLogin(environment.emenuDomain, 'self')
    });
  }

  /**
   * Go to SPARK profile
   */
  goToMyAccount() {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.userData.user_login.username)
    const url = new URL(environment.myaccountDomain);
    url.searchParams.set('accIndex', index);
    window.open(url)
  }

  /**
   * Switch logging account
   * @param i Account index
   */
  switchAccount(i) {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.accountList[i].username)
    const url = new URL(location.href);
    url.searchParams.set('accIndex', index);
    window.location.replace(url)
  }

  /**
   * Add new login account
   */
  addNewAccount() {
    this._utility.goToLogin(window.location.href, 'replace')
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Utility
  // -----------------------------------------------------------------------------------------------------
  compareCategoryObjects(object1: any, object2: any) {
    console.log(object1, object2);
    if (object1.language_code == object2) {
    } this.languageCtrl.patchValue(object1)
    return object1;
  }
}
