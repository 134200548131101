import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SparkConfirmationDialogComponent } from './dialog/dialog.component';
import { SparkConfigService } from '../config/config.service';
import { SparkConfirmationService } from './confirmation.service';

@NgModule({
    declarations: [
        SparkConfirmationDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule
    ],
    providers: [
        SparkConfirmationService
    ]
})
export class SparkConfirmationModule {
    /**
     * Constructor
     */
    constructor(private _fuseConfirmationService: SparkConfigService) {
    }
}
