/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empty',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss']
})
export class EmptyLayoutComponent implements OnInit {
  orgURL = environment.orgURL
  currentYear = new Date().getFullYear()
  scheme: any;
  constructor(
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer,
    private _sparkConfigService: SparkConfigService,

  ) { }

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    // Hide chat
    if (window.hasOwnProperty('Tawk_API') && (Object.keys(window['Tawk_API']).length != 0)) {
      window['Tawk_API'].hideWidget()
    }

    this._updateTheme()
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.scheme = config.scheme;
        this._updateScheme()
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * After view load
   */
  ngAfterViewInit() {
    this._document.body.classList.add('spark-splash-screen-hidden');
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });
    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-blue`);
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
      this.overlayContainer.getContainerElement().classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
      this.overlayContainer.getContainerElement().classList.remove(this.scheme);
    }
  }

  setScheme(scheme): void {

  }
}
