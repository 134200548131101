import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { UtilityService } from '../core/common-service/utility.service';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {
  cartSubscription = new Subject<any>()
  constructor(
    private _utility: UtilityService
  ) {
  }

  get notification$(): Observable<any> {
    return this.cartSubscription.asObservable()
  }

  async setCartData(name, data) {
    const now = new Date()
    data.expiry = now.getTime() + 900000;
    await localStorage.setItem(this._utility.encrypt(name), this._utility.encryptAES(JSON.stringify(data)))
    this.cartSubscription.next(data)
  }

  removeCartData(name) {
    localStorage.removeItem(this._utility.encrypt(name))
    this.cartSubscription.next(null)
  }

  clearCartData(name) {
    localStorage.removeItem(this._utility.encrypt(name))
    this.cartSubscription.next(null)
  }

  getCartData(name): any {
    let storage = localStorage.getItem(this._utility.encrypt(name))
    const item = storage ? JSON.parse(this._utility.decryptAES(storage)) : ''
    if (!item)
      return null
    const now = new Date()
    if (now.getTime() > item.expiry) {
      this.clearCartData(name)
      return null
    }
    return item
  }

  getCartCount(name): any {
    let data = this.getCartData(name)
    return data ? data.length : 0
  }
}
