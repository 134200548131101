<div class="flex flex-col h-full mat-app-background">
  <router-outlet *ngIf="true"></router-outlet>
  <div class="footer-text secondary-text" *transloco="let t">
    <a href="{{orgURL}}" class="hover:underline" target="blank"> {{t('reserved_spark_technologies')}}
    </a> {{ currentYear }}<br>
    <ul class="copyright-links">
      <li><a href="{{orgURL}}/policy/privacy-policy" target="blank">{{t('privacy_policy')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/cookie-policy" target="blank">{{t('cookie_policy')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/refund-policy" target="blank">{{t('refund_policy')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/disclaimer" target="blank">{{t('disclaimer')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/terms-of-use" target="blank">{{t('t_of_use')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/terms-and-conditions" target="blank">{{t('t_c')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/trademarks" target="blank">{{t('trademarks')}}</a> | </li>
      <li><a href="{{orgURL}}/policy/terms-of-services" target="blank">{{t('t_of_services')}}</a></li>
    </ul>
  </div>
</div>
