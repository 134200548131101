<ng-container *transloco="let t">
  <div class="flex justify-between">
    <h3 class="text-2xl font-medium mb-4">{{t('tour.title.quick_setup_tour')}}</h3>
  </div>
  <div class="stepper-horizontal" id="stepper1">
    <div class="step" [class.editing]="currentStep==0" [class.done]="currentStep>=1">
      <div class="step-circle"><span>1</span></div>
      <div class="step-subtitle">{{t('tour.title.step_1')}}</div>
      <div class="step-title">{{t('tour.title.menu_types')}}</div>
      <div class="step-optional">{{t('tour.title.setup_menu_types_which_you_provide')}}</div>
      <div class="step-bar-left"></div>
      <div class="step-bar-right"></div>
    </div>
    <div class="step" [class.editing]="currentStep==1" [class.done]="currentStep>=2">
      <div class="step-circle"><span>2</span></div>
      <div class="step-subtitle">{{t('tour.title.step_2')}}</div>
      <div class="step-title">{{t('tour.title.table_setup')}}</div>
      <div class="step-optional" *ngIf="currentStep>=1">{{t('tour.title.setup_tables_for_dine_in_facility')}}</div>
      <div class="step-bar-left"></div>
      <div class="step-bar-right"></div>
    </div>
    <div class="step" [class.editing]="currentStep==2" [class.done]="currentStep>=3">
      <div class="step-circle"><span>3</span></div>
      <div class="step-subtitle">{{t('tour.title.step_3')}}</div>
      <div class="step-title">{{t('tour.title.qr_generation')}}</div>
      <div class="step-optional" *ngIf="currentStep>=2">{{t('tour.title.generate_table_wise_and_take_away_qr')}}</div>
      <div class="step-bar-left"></div>
      <div class="step-bar-right"></div>
    </div>
    <div class="step" [class.editing]="currentStep==3" [class.done]="currentStep>=4">
      <div class="step-circle"><span>4</span></div>
      <div class="step-subtitle">{{t('tour.title.step_4')}}</div>
      <div class="step-title">{{t('tour.title.add_menu')}}</div>
      <div class="step-optional" *ngIf="currentStep>=3">{{t('tour.title.add_import_your_menues')}}</div>
      <div class="step-bar-left"></div>
      <div class="step-bar-right"></div>
    </div>
    <div class="step" [class.editing]="currentStep==4" [class.done]="currentStep>=5">
      <div class="step-circle"><span>5</span></div>
      <div class="step-subtitle">{{t('tour.title.step_5')}}</div>
      <div class="step-title">{{t('tour.title.complete')}}</div>
      <div class="step-optional" *ngIf="currentStep==4">{{t('tour.title.ready_to_show_your_menu_to_customers')}}</div>
      <div class="step-bar-left"></div>
      <div class="step-bar-right"></div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <!-- Cancel -->
    <button mat-button type="button" (click)="skip()" class="add-round-button">{{t('action.skip')}}</button>
    <button mat-button type="button" (click)="next()" class="add-round-button success-button">
      <ng-container *ngIf="currentStep<4">{{t('message.next')}}</ng-container>
      <ng-container *ngIf="currentStep==4">{{t('action.finish')}}</ng-container>
    </button>
  </div>
</ng-container>