<ng-container *transloco="let t">
  <h2 mat-dialog-title class="mb-0 text-center">{{t('menu_type.title.almost_done_one_more_step')}}</h2>
  <p class="text-center">{{t('menu_type.title.select_your_menu_type')}}</p>
  <mat-dialog-content class="pb-4 mt-6">
    <ul class="flex justify-around items-center">
      <li class="type" *ngFor="let menuType of foodMenuTypes; let i=index">
        <input type="checkbox" class="hidden m-auto" id="menuType{{i}}" [checked]="checkMenuType(menuType)>=0"
          (change)="setMenuType(menuType, $event.target)">
        <label for="menuType{{i}}">
          <div
            class="services-item relative rounded-lg cursor-pointer text-center flex flex-col px-6 py-4 shadow-md w-36">
            <img class="m-auto" src="assets/icons/{{menuType.menu_type_slug}}.png" alt="">
            <h6 class="mt-3">{{menuType.menu_type}}</h6>
            <!-- <div [class.disabled]="('manage' | ablePure: 'basic' | async) && menuType.menu_type_slug=='take_away'"
                            class="w-full h-full left-0 top-0 absolute flex items-center justify-center">
                            <mat-icon
                                *ngIf="('manage' | ablePure: 'basic' | async) && menuType.menu_type_slug=='take_away'">
                                lock
                            </mat-icon>
                        </div> -->
          </div>
        </label>
      </li>
    </ul>
    <mat-error class="mt-4 text-center" *ngIf="getMenuTypeList().errors?.serverType">
      {{getMenuTypeList().errors?.serverType.message}}
    </mat-error>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" class="add-round-button"
      (click)="dialogRef.close()">{{t('menu_type.title.skip_for_now')}}</button>
    <button mat-button [disabled]="getMenuTypeList().controls.length<=0 || isLoading"
      class="success-button add-round-button" cdkFocusInitial (click)="addFoodMenuType()">
      <span *ngIf="!isLoading">{{t('menu_type.title.finish')}}</span>
      <div *ngIf="isLoading" style="padding: 8px;">
        <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
      </div>
    </button>
  </div>
</ng-container>