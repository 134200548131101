import { NgModule } from '@angular/core';
import { SparkSplashScreenService } from './splash-screen.service';

@NgModule({
    providers: [
        SparkSplashScreenService
    ]
})
export class SparkSplashScreenModule {
    /**
     * Constructor
     */
    constructor(private _sparkSplashScreenService: SparkSplashScreenService) {
    }
}
