<div class="sidebar" fxLayout="column">
    <!-- Header -->
    <ng-content select="[navigationHeader]"></ng-content>
    <div class="flex flex-1 flex-col">
        <!-- Navigation -->
        <div class="nav">
            <ng-container *ngFor="let item of navigation ;let i = index;">
                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <app-navigation-basic [isSubmenu]="false" [index]="i" [item]="item" (mouseover)="menuHoverIn(i)"
                        (mouseout)="menuHoverOut()" [class.hover]="hoverId==i">
                    </app-navigation-basic>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <app-navigation-collapsable [index]="i" [item]="item" (mouseover)="menuHoverIn(i)"
                        (mouseout)="menuHoverOut()" [class.hover]="hoverId==i">
                    </app-navigation-collapsable>
                </ng-container>
            </ng-container>
            <div class="highlight-box" [class.light] [style.top]="top+'px'">
            </div>
        </div>

    </div>
    <!-- Footer -->
    <ng-content select="[navigationFooter]"></ng-content>
</div>