import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalApiService {
  constructor(
    private _httpService: HttpService
  ) { }

  getServiceTypes() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/service/type/getAll').subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        //this._error.showError(error);
        reject()
      })
    })
  }

  getAllFoodMenuTypeList(portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/food/menu/type/getAll', portal).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }


  getFoodTypeList() {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/food/type/getAll', 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }
}
