import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findInArray',
  pure: false
})
export class FindInArrayPipe implements PipeTransform {

  transform(array: any, ...args: any[]): any {
    let data = array.find((item) => item[args[0]] == args[1])
    return data || data && data.length > 0 ? data : false
  }

}
