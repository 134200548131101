<div class="spark-splash-screen hotel-splash-screen" [style.background-color]="scheme=='dark'?'#292929':'white'"
  *ngIf="showSplash">
  <!-- Splash screen -->
  <div class="center">
    <div class="spinner-logo-box">
      <div class="flex flex-col spinner-logo items-center justify-center m-auto md:w-1/2 w-52">
        <img id="" [class.rounded-full]="hotelData?.business_logo"
          [src]="hotelData?.business_logo?hotelData?.business_logo:'assets/logo/orderplzz-full-logo.svg'">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *transloco="let t">
  <div class="h-full" [class.overflow-hidden]="showSplash">
    <ng-container *ngIf="!showSplash">
      <!-- Desktop Toolbar -->
      <div class="w-full px-4 md:hidden bg-primary text-white shadow-sm">
        <div class="flex justify-end md:justify-around items-center">
          <ul class="landing-menu-bar md:w-full flex items-center gap-2 justify-between">
            <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="isActiveMatchOptions"
              routerLink="/menu/{{hotelData?.partner_name}}/{{hotelData?.menuType}}" queryParamsHandling="merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z"
                  fill="white" />
              </svg>
            </li>
            <!-- <li *ngIf="hotelData?.menuType && hotelData?.menuType=='dine_in' &&
                        !('manage' | ablePure: 'basic' | async)"
              routerLink="/menu/{{hotelData.partner_name}}/{{hotelData.menuType}}/my-cart" queryParamsHandling="merge">
              <div [matBadge]="cartCount>0?cartCount:null" matBadgeColor="warn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="1">
                    <path opacity="1" fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.91058 20.5884C5.91058 19.7484 6.59058 19.0684 7.43058 19.0684C8.26058 19.0684 8.94058 19.7484 8.94058 20.5884C8.94058 21.4184 8.26058 22.0984 7.43058 22.0984C6.59058 22.0984 5.91058 21.4184 5.91058 20.5884ZM17.1606 20.5884C17.1606 19.7484 17.8406 19.0684 18.6806 19.0684C19.5106 19.0684 20.1906 19.7484 20.1906 20.5884C20.1906 21.4184 19.5106 22.0984 18.6806 22.0984C17.8406 22.0984 17.1606 21.4184 17.1606 20.5884Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20.1908 6.34884C20.8008 6.34884 21.2008 6.55884 21.6008 7.01884C22.0008 7.47884 22.0708 8.13884 21.9808 8.73784L21.0308 15.2978C20.8508 16.5588 19.7708 17.4878 18.5008 17.4878H7.5908C6.2608 17.4878 5.1608 16.4678 5.0508 15.1488L4.1308 4.24784L2.6208 3.98784C2.2208 3.91784 1.9408 3.52784 2.0108 3.12784C2.0808 2.71784 2.4708 2.44784 2.8808 2.50784L5.2658 2.86784C5.6058 2.92884 5.8558 3.20784 5.8858 3.54784L6.0758 5.78784C6.1058 6.10884 6.3658 6.34884 6.6858 6.34884H20.1908ZM14.1308 11.5478H16.9008C17.3208 11.5478 17.6508 11.2078 17.6508 10.7978C17.6508 10.3778 17.3208 10.0478 16.9008 10.0478H14.1308C13.7108 10.0478 13.3808 10.3778 13.3808 10.7978C13.3808 11.2078 13.7108 11.5478 14.1308 11.5478Z"
                      fill="white" />
                  </g>
                </svg>
              </div>
            </li> -->
            <li>
              <!-- Language -->
              <div>
                <button mat-button class="text-white min-w-0 sm:py-2" [matMenuTriggerFor]="languages">
                  <span class="flex items-center">
                    <span class="relative w-6 shadow rounded-sm overflow-hidden">
                      <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                      <img class="w-6" [src]="'assets/icons/'+languageCtrl.value?.icon+'.jpg'">
                    </span>
                    <span class="ml-3 sm:hidden">{{languageCtrl.value.language_name}}</span>
                  </span>
                </button>
                <!-- Language menu -->
                <mat-menu [xPosition]="'before'" #languages="matMenu">
                  <ng-container *ngFor="let lang of availablelanguages">
                    <button mat-menu-item (click)="setLanguage(lang.language_code)">
                      <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang}"></ng-container>
                    </button>
                  </ng-container>
                </mat-menu>

                <!-- Flag image template -->
                <ng-template let-lang #flagImage>
                  <span class="flex items-center">
                    <span class="relative w-6 shadow rounded-sm overflow-hidden">
                      <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                      <img class="w-6" [src]="'assets/icons/'+lang?.icon+'.jpg'">
                    </span>
                    <span class="ml-3">{{lang.language_name}}</span>
                  </span>
                </ng-template>
              </div>
            </li>
            <li *ngIf="userData">
              <button class="my-account-menu" mat-button [matMenuTriggerFor]="menu">
                <div class="user-circle-avatar">
                  <img
                    src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/placeholder/user.jpg'}}">
                  <!-- <h3 *ngIf="!userData?.profile_photo_path && userData?.first_name">
                                    {{userData?.first_name.charAt(0) |
                                    uppercase}}</h3> -->
                  <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">person
                  </mat-icon>
                </div>
                <h3 class="user-name">{{userData?.first_name}}</h3>
              </button>
              <mat-menu #menu="matMenu">
                <span>
                  <div class="flex items-center px-4 py-4">
                    <div class="user-circle-avatar menu-user-circle-avatar">
                      <img
                        src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/placeholder/user.jpg'}}">
                      <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">
                        person
                      </mat-icon>
                    </div>
                    <div class="ml-3">
                      <span class="text-base font-medium">{{userData?.first_name}}
                        {{userData?.last_name}}</span>
                      <p class="mt-0 text-sm">{{userData?.user_login?.username}}
                        <span
                          class="text-xs">({{userData?.user_login.user_role_name.user_role_drc_key==1?'Business':"Personal"}})</span>
                      </p>
                    </div>
                  </div>
                  <div class="text-center mb-3 mt-1">
                    <!-- <button mat-button>{{t('my_profile')}}</button> -->
                    <button mat-stroked-button (click)="goToMyAccount()" class="rounded-full font-normal">
                      {{t('user_menu.title.manage_profile')}}</button>
                  </div>
                  <button mat-menu-item (click)="switchAccount(i)" *ngFor="let user of accountList;let i=index"
                    class="flex items-center w-full">
                    <div>
                      <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==2">
                        account_box</mat-icon>
                      <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==1">
                        assignment_ind</mat-icon>
                    </div>
                    <div class="flex-1">
                      <span>
                        {{user.full_name}}
                      </span>
                      <span class="text-xs">({{user.role==1?'Business':"Personal"}})</span>
                    </div>
                    <mat-icon class="material-icons-outline mr-0 icon-size-5 overlay-menu-icon">
                      open_in_new</mat-icon>
                  </button>
                  <button mat-menu-item (click)="addNewAccount()">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">person_add
                    </mat-icon>{{t('user_menu.title.sign_with_different_account')}}
                  </button>
                  <button mat-menu-item *ngIf="hotelData?.menuType &&
                                    !('manage' | ablePure: 'basic' | async)" queryParamsHandling="merge"
                    routerLink="/menu/{{hotelData?.partner_name}}/{{hotelData?.menuType}}/my-orders">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">list_alt
                    </mat-icon>{{t('user_menu.title.my_orders')}}
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon class="material-icons-outlined overlay-menu-icon">logout
                    </mat-icon>{{t('auth.title.logout')}}
                  </button>
                </span>
              </mat-menu>
            </li>
          </ul>
        </div>
      </div>

      <!-- Mobile Navbar -->
      <div @slideInOut *ngIf="showBottomNav" [class.bottom-bar-shadow]="showShadow"
        class="fixed hidden md:block bottom-0 bottom-bar w-full z-20">
        <div class="w-full">
          <svg width="100%" viewBox="0 0 390 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M114.506 0H40C17.9086 0 0 17.9086 0 40V657C0 679.091 17.9086 697 40 697H350C372.091 697 390 679.091 390 657V40C390 17.9086 372.091 0 350 0H277.995C272.816 0 267.687 1.00561 262.891 2.961L244.307 10.539C239.512 12.4944 234.383 13.5 229.204 13.5H162.93C157.656 13.5 152.434 12.4569 147.564 10.4306L129.872 3.06938C125.003 1.04314 119.78 0 114.506 0Z"
              fill="white" />
          </svg>
        </div>
        <div class="w-full">
          <div class="-mt-4 flex justify-evenly pb-6 w-full bg-white">
            <!-- Home -->
            <div class="nav-button">
              <button mat-flat-button [routerLinkActiveOptions]="isActiveMatchOptions"
                routerLink="/menu/{{this.hotelData?.partner_name}}/{{hotelData?.menuType}}" queryParamsHandling="merge"
                [routerLinkActive]="'bottom-active-button'" class="nav-round-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z"
                    fill="black" />
                </svg>
                <span style="display: none;" class="ml-2">{{t('user_menu.title.home')}}</span>
              </button>
            </div>

            <!-- Cart -->
            <div class="nav-button" *ngIf="!('manage' | ablePure: 'basic' | async) && isAvailable
              && ((uid && hotelData.menuType == 'dine_in') || hotelData.menuType == 'take_away')">
              <button mat-flat-button routerLink="/menu/{{this.hotelData.partner_name}}/{{hotelData.menuType}}/my-cart"
                queryParamsHandling="merge" [routerLinkActive]="'bottom-active-button'"
                [routerLinkActiveOptions]="isActiveMatchOptions" class="nav-round-button">
                <mat-icon [matBadge]="cartCount>0?cartCount:null" matBadgeColor="warn"
                  [svgIcon]="'icon-cart'"></mat-icon>
                <span style="display: none;" class="ml-2">{{t('user_menu.title.cart')}}</span>
              </button>
            </div>

            <!-- More
          <div class="nav-button">
            <button mat-flat-button class="nav-round-button">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path opacity="0.4"
                    d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                    fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.9805 13.2896C11.2705 13.2796 10.7005 12.7096 10.7005 11.9996C10.7005 11.2996 11.2805 10.7196 11.9805 10.7296C12.6905 10.7296 13.2605 11.2996 13.2605 12.0096C13.2605 12.7096 12.6905 13.2896 11.9805 13.2896ZM7.37009 13.2896C6.67009 13.2896 6.09009 12.7096 6.09009 12.0096C6.09009 11.2996 6.66009 10.7296 7.37009 10.7296C8.08009 10.7296 8.65009 11.2996 8.65009 12.0096C8.65009 12.7096 8.08009 13.2796 7.37009 13.2896ZM15.3103 12.0096C15.3103 12.7096 15.8803 13.2896 16.5903 13.2896C17.3003 13.2896 17.8703 12.7096 17.8703 12.0096C17.8703 11.2996 17.3003 10.7296 16.5903 10.7296C15.8803 10.7296 15.3103 11.2996 15.3103 12.0096Z"
                    fill="black" />
                </g>
              </svg>
            </button>
          </div>-->

            <!-- Profile -->
            <div class="nav-button">
              <button mat-flat-button class="nav-round-button" [routerLinkActiveOptions]="isActiveMatchOptions"
                [routerLinkActive]="'bottom-active-button'"
                routerLink="/menu/{{this.hotelData?.partner_name}}/{{hotelData?.menuType}}/my-profile"
                queryParamsHandling="merge">
                <mat-icon [svgIcon]="'icon-profile'"></mat-icon>
                <span style="display: none;" class="ml-2">{{t('user_menu.title.profile')}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Routing area -->
    <div [class.loading-content]="showSplash" class="h-full routing-area">
      <router-outlet *ngIf="!hasError"></router-outlet>
    </div>

    <!-- Footer -->
    <div [class.warning-down]="_mediaObserver.isActive('lt-md')" [class.warning-up]="!_mediaObserver.isActive('lt-md')"
      class="bg-red-600 fixed py-0.5 text-center text-white w-full warning-down z-20"
      *ngIf="!showSplash && (!isAvailable || isAvailable && closedIn) && hotelData">
      <div *ngIf="!showSplash && !isAvailable">
        <span *ngIf="!availableIn"
          class="text-sm">{{t('user_menu.title.hotel_is_closed',{hotel:hotelData?.business_name})}}</span>
        <span *ngIf="availableIn" class="text-sm">{{t('user_menu.title.hotel_will_be_available_in_time',
          {hotel:hotelData?.business_name,time:availableIn})}}</span>
      </div>
      <div *ngIf="!showSplash && isAvailable && closedIn">
        <span *ngIf="closedIn" class="text-sm">{{t('user_menu.title.hotel_will_be_closed_in_time',
          {hotel:hotelData?.business_name,time:closedIn})}}</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="hasError">
    <!-- Hotel not found -->
    <div class="flex flex-col h-full items-center justify-center">
      <div>
        <img class="m-auto" width="550px" src="assets/illustrations/broken-robot.png">
        <h3 class="mt-4 text-4xl text-center">{{t('user_menu.message.no_hotel_found')}}</h3>
      </div>
    </div>
  </ng-container>
</ng-container>