import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { UserService } from 'src/app/core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { SparkNavigationService } from '../navigation.service';

@Component({
  selector: 'app-navigation-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicComponent implements OnInit {
  @Input() item
  @Input() index
  @Input() isSubmenu

  isActiveMatchOptions: any;
  userData: any;
  isSidebarFold: any;

  businessRoleKey = this._utility.encrypt('business');
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _utility: UtilityService,
    private _userService: UserService,
    private _navigationService: SparkNavigationService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isActiveMatchOptions = {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact'
    };

    // 1. Get user data
    // 2. Get account list from cookies
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })

    this._navigationService.onSidebarFold.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.isSidebarFold = data
      this.cdr.markForCheck()
    })
  }

  redirectTo(url) {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.userData.user_login.username)
    window.open(url, '_blank')
  }

}
