import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ability, AbilityBuilder } from '@casl/ability';
import { reject } from 'lodash';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  constructor(private _httpService: HttpService,
    private ability: Ability,
    private _http: HttpClient) { }

  getMaterialIcons() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('admin/gl/icons/getIcons').subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getPrefixes() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/attribute/user/prefix/getAll?is_list=1').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {

          reject(error)
        })
    })
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/attribute/user/role/getAll?is_list=1').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getSubscriptionLevel() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/subscription/getLevel').subscribe((data: any) => {
        this.updateAbility(data.data.level)
        resolve(data.data);

      },
        (error) => {

          reject(error)
        })
    })
  }

  private updateAbility(data) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);

    if (data <= 1) {
      can('manage', 'basic')
    }
    else if (data <= 2) {
      can('manage', 'medium')
    }
    else if (data <= 3) {
      can('manage', 'premium')
    }
    this.ability.update(rules);
  }

  // get country codes
  getCountryCode(portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/country/getCountryCodes', portal).subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCountries(portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/attribute/country/getAll', portal).subscribe((data: any) => {
        if (data.status_code == 200) {

          resolve(data.data);
        }
        else {
          reject(data)
        }
      },
        (error) => {

          reject(error)
        })
    })
  }

  getStatesByCountry(countryId, portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/attribute/state/getAll?country_key=' + countryId, portal).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCitiesByState(stateId, portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/attribute/city/getAllByState?state_key=' + stateId, portal).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getLanguages(portal) {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/attribute/language/getAll', portal).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }


}
