/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Page
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject, first, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuLayoutService } from 'src/app/layout/layouts/menu-layout/menu-layout.service';
import { GlobalApiService } from '../../common/global-api.service';
import { MenuCardService } from '../menu-card.service';
import { SingleOrderSheetComponent } from './single-order-sheet/single-order-sheet.component';
const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, display: AUTO_STYLE })),
      state('false', style({ height: '0', display: 'none' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class MyOrdersComponent implements OnInit {

  dataSource: any = []
  menuTypes: any[] = []
  items: any[] = []

  searchKeyword = ''
  pagination = {
    length: 0,
    page: 0,
    size: 10
  }
  keyword = ''
  selectedTab: any = 0;
  menuTypeSlug: any;
  menuTypeKey: any;
  hotelData: any;

  hasError = false;
  isLoading: boolean = false;
  isItemsLoading: boolean = false;
  isItemsToggled = false
  toggledId = -1

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  orderColumns: string[] = ['order_id', 'partner_name', 'table_name', 'total_amount', 'payment_mode', 'created_at', 'order_status', 'view'];
  partnerName: any;

  constructor(
    private _utility: UtilityService,
    public dialog: MatDialog,
    private _activeRoute: ActivatedRoute,
    private menuCardService: MenuCardService,
    private _globalApiService: GlobalApiService,
    private menuLayoutService: MenuLayoutService,
    private _bottomSheet: MatBottomSheet,
    private splashScreenStateService: SplashScreenStateService,
  ) {

    // Get route param
    _activeRoute.params.subscribe((param) => {
      this.partnerName = param['partnerName']
      this.menuTypeSlug = param['menuType']
    })

    // Get hotel data
    menuLayoutService.hotelData.pipe(first(val => val), takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.hotelData = data
    }, (error) => {
      this.hasError = true
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {

    // Show splash for 2 sec
    setTimeout(() => {
      this.splashScreenStateService.stop()
    }, 2000);

    this.fetchOrders(0)
    // // Get menu type
    // this._globalApiService.getAllFoodMenuTypeList('user').then((data: any) => {
    //   this.hasError = false
    //   this.menuTypes = data
    //   // Select tab according to menu type
    //   this.selectedTab = this.menuTypes.findIndex((type) => type.menu_type_slug == this.menuTypeSlug)
    //   this.tabGroup.selectedIndex = this.selectedTab
    // }).catch((error) => {
    //   this.hasError = true
    // })
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * On menu type tab change
   * @param event
   */
  onTabChanged(event) {
    this.selectedTab = this.tabGroup.selectedIndex;
    this.dataSource = []
    this.menuTypeKey = event.tab.textLabel
    this.isLoading = true;
    this.fetchOrders(0, this.pagination.size, this.searchKeyword)
  }

  /**
   * Filter (Search filter)
   * @param event
   */
  applyFilter(event) {
    this.isLoading = true;
    this.dataSource = []
    this.searchKeyword = event.target.value
    this.fetchOrders(0, this.pagination.size, event.target.value)
  }

  /**
   * Pagination change
   * @param event
   */
  pageChanged(event) {
    this.isLoading = true;
    this.dataSource = []
    this.pagination = {
      length: 0,
      page: 0,
      size: 10
    }
    this.fetchOrders(event.pageIndex, event.pageSize, this.keyword)
  }

  /**
   * Fetch orders
   * @param page
   * @param size
   * @param keyword
   */
  fetchOrders(page, size = this.pagination.size, keyword = '') {
    this.isLoading = true;
    this.hasError = false;
    this.dataSource = []
    this.menuCardService.getAllOrders(page + 1, this.hotelData?.partner_business_key, this.hotelData?.business_branch?.business_branch_key, keyword, size).then((data: any) => {
      this.isLoading = false
      if (data) {
        this.dataSource = data.data
        this.pagination = {
          length: data.total,
          page: page,
          size: size
        }
      }
      else {
        this.pagination = {
          length: 0,
          page: 0,
          size: size
        }
      }
    }).catch((error) => {
      this.hasError = true;
    }).finally(() => {
      this.isLoading = false
    })
  }

  toggleItems(i, key) {
    this.items = []
    this.isItemsToggled = true;
    if (this.toggledId == i)
      this.toggledId = -1
    else
      this.toggledId = i
    if (this.isItemsToggled) {
      this.isItemsLoading = true
      this.menuCardService.getOrderById(key).then((data: any) => {
        this.items = data.order_item
      }).finally(() => {
        this.isItemsLoading = false
      }).catch((error) => {
        this.hasError = true
      })
    }

  }

  getVariants(variants) {
    return variants.map(s => s.partner_food_menu_variant.variant_name).join(', ')
  }

  getAddons(addons) {
    return addons.map(s => s.partner_food_menu_addon.addon_name)
  }

  menuItemSheet(key): void {
    const dialogRef = this._bottomSheet.open(SingleOrderSheetComponent, {
      data: { orderKey: key },
      panelClass: ['opz-sheet', 'dark-component'],
    },);

    dialogRef.afterDismissed().subscribe(result => {
      //this.getCartCount()
    });
  }
}
