import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingLayoutComponent } from './landing-layout.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserAuthService } from 'src/app/core/user-auth/user-auth.service';


@NgModule({
  declarations: [
    LandingLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    SharedModule,
    HttpClientModule,
    MatSidenavModule,
    MatMenuModule
  ],
  exports: [
    LandingLayoutComponent,
  ],
  providers: [
    UserAuthService
  ]
})
export class LandingLayoutModule { }
