import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserAuthService } from '../user-auth.service';
import { UtilityService } from '../../common-service/utility.service';
@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  personalKey = this.utility.encrypt("personal")
  businessKey = this.utility.encrypt("business")
  private renderer: Renderer2;
  constructor(
    private _authService: UserAuthService,
    private _router: Router,
    rendererFactory: RendererFactory2,
    private _activate: ActivatedRoute,
    private utility: UtilityService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url = state.url.slice(1).split('/')[0];
    const redirectUrl = state.url === '/logout' || url === 'unlock-session' ? '/' : state.url;
    let accountIndex = route.queryParams?.accIndex ? route.queryParams.accIndex : null
    return this._check(redirectUrl, accountIndex, state);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = state.url.slice(1).split('/')[0];
    const redirectUrl = state.url === '/logout' || url === 'unlock-session' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(redirectURL: string, accountIndex?, activated?): Promise<boolean> {
    // Check the authentication data
    return this._authService.check()
      .then((authenticated) => {
        let key = "personalSecret";

        // Remove account index from current url
        let url = new URL(environment.emenuDomain + redirectURL)
        url.searchParams.delete("accIndex");

        // Prepare login url
        // let destinationUrl = new URL(environment.loginDomain + '?reference=' + encodeURIComponent(url.href))
        // destinationUrl.searchParams.set('portal', environment.appSlug)

        // Check for cookie storage
        if (this.utility.getCookie(key)) {
          // Get account list
          let accounts = this.utility.getSecret("personalSecret")

          // Get local session
          let localSession = this.utility.decryptAES(authenticated)

          // Check for account index
          if (accountIndex) {
            let cookie = Object.values(accounts)[accountIndex] as any
            var refreshUrl = new URL(window.location.origin + activated.url);
            refreshUrl.searchParams.delete("accIndex");
            // Check for local session exist or local session username doesnt match in cookie
            if (!localSession || (localSession && JSON.parse(localSession).username != cookie?.username)) {
              return this._authService.signIn(cookie).then((res) => {
                if (res.success == 0) {
                  this.utility.showFailure(res.message)
                  if (res.status_code == 400) {
                    this.utility.removeSession(cookie?.username, cookie.role)
                  }
                  this.utility.goToLogin(url.href, 'self')
                  return Promise.resolve(false);
                }
                else {
                  let url = refreshUrl.pathname + refreshUrl.search
                  this._router.navigateByUrl(url, { replaceUrl: true, })
                  //window.location.replace(refreshUrl.href)
                  return Promise.resolve(true);
                }
              });
            }
          }
          // If local session doesn't exist
          else if (!authenticated) {
            // Search for personal accounts
            let personalAccounts = accounts.filter((acc) => acc.role == 2)
            if (personalAccounts.length == 1) {
              // Sign in with first index acc
              let cookie = personalAccounts[0] as any
              return this._authService.signIn(cookie).then((res) => {
                if (res.success == 0) {
                  this.utility.showFailure(res.message)
                  if (res.status_code == 400) {
                    this.utility.removeSession(cookie?.username, cookie.role)
                  }
                  this.utility.goToLogin(url.href, 'self')
                  return Promise.resolve(false);
                }
                return Promise.resolve(true);
              });
            }
            // Go to login
            else {
              this.utility.goToLogin(url.href, 'self')
              return Promise.resolve(false);
            }
          }
          // If local session doesn't exist in cookie
          else if (!accounts.find((crd) => crd.username == JSON.parse(localSession).username)) {
            this.utility.removeSession(JSON.parse(localSession).username, 2)
            this.utility.goToLogin(url.href, 'self')
            // Prevent the access
            return Promise.resolve(false);
          }
        }
        // Go to login
        else {
          this.utility.goToLogin(url.href, 'self')
          // Prevent the access
          return Promise.resolve(false);
        }

        var refreshUrl = new URL(window.location.origin + activated.url);
        if (refreshUrl.searchParams.get("accIndex")) {
          refreshUrl.searchParams.delete("accIndex");
          //window.location.replace(refreshUrl.href)
          let url = refreshUrl.pathname + refreshUrl.search
          this._router.navigateByUrl(url, { replaceUrl: true })
        }
        // Allow the access
        return Promise.resolve(true);
      });

    //     .pipe(
    //         switchMap((authenticated) => {
    //             let key = CryptoJS.SHA256("personalSecret");
    //             if (this.cookieService.get(key)) {
    //                 // If the user is not authenticated...
    //                 if (!authenticated) {
    //                     let cred = CryptoJS.AES.decrypt(this.cookieService.get(key), environment.passphrase).toString(CryptoJS.enc.Utf8)
    //                     return this._authService.signIn(JSON.parse(cred)).pipe(map((res) => {
    //                         return true;
    //                     }));
    //                 }

    //             } else {
    //                 //this._router.navigate(['login'], { queryParams: { redirectURL } });
    //                 let url = encodeURIComponent(environment.emenuDomain + redirectURL).toString()
    //                 window.location.href = environment.loginDomain + '/' + environment.appSlug + '?reference=' + url + '&role=2';
    //                 // Prevent the access
    //                 return of(false);
    //             }
    //             // Allow the access
    //             return of(true);
    //         })
    //     );
  }

}
