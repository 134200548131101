import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SparkModule } from 'src/@spark/spark.module';
import { TranslocoRootModule } from '../transloco-root.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { HttpClientModule } from '@angular/common/http';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { LandingLayoutModule } from './layouts/landing-layout/landing-layout.module';
import { AdminModule } from './layouts/admin/admin-layout.module';
import { } from './layouts/empty/empty-layout.component';
import { EmptyModule } from './layouts/empty/empty-layout.module';
import { MenuLayoutComponent } from './layouts/menu-layout/menu-layout.component';
import { MenuLayoutModule } from './layouts/menu-layout/menu-layout.module';
@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule, RouterModule,
    MatSidenavModule,
    MatTooltipModule,
    MatBadgeModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    SparkModule,
    LandingLayoutModule,
    AdminModule,
    EmptyModule,
    MenuLayoutModule,
  ],
  exports: [
    LayoutComponent,

    FlexLayoutModule,
  ],
  providers: [
  ]
})
export class LayoutModule { }
