import { ModuleWithProviders, NgModule } from '@angular/core';
import { SPARK_APP_CONFIG } from './config.constants';
import { SparkConfigService } from './config.service';

@NgModule()
export class SparkConfigModule {
    /**
     * Constructor
     */
    constructor(private _fuseConfigService: SparkConfigService) {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<SparkConfigModule> {
        return {
            ngModule: SparkConfigModule,
            providers: [
                {
                    provide: SPARK_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
