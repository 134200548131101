<div class="loader" *ngIf="isLoading">
  <mat-spinner [diameter]="50" style="margin: auto;"></mat-spinner>
</div>
<image-cropper style="padding: 0;" [resizeToWidth]="256" [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" [transform]="transform" format="png"
  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" [roundCropper]="isRound" allowMoveImage="true"
  (cropperReady)="cropperReady()" [onlyScaleDown]="true" (loadImageFailed)="loadImageFailed()"></image-cropper>
<div class="flex gap-2 justify-center mt-2">
  <button mat-icon-button (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
  <button mat-icon-button (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
</div>
<div mat-dialog-actions class="justify-end">
  <ng-container *transloco="let t">
    <button mat-button type="button" class="add-round-button" (click)="dialogRef.close()" [disabled]="isUploading"
      style="margin-right: 10px;">{{t('action.cancel')}}</button>
    <mat-spinner *ngIf="isUploading" [diameter]="30"></mat-spinner>
    <button mat-button class="add-round-button success-button" (click)="setImage()" *ngIf="!isUploading"
      cdkFocusInitial>
      {{t('crop_image')}}
    </button>
  </ng-container>
</div>