/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Modal
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.scss']
})
export class TourModalComponent implements OnInit {

  currentStep = 0;

  constructor(
    public dialogRef: MatDialogRef<TourModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  next() {
    this.currentStep += 1
    if (this.currentStep > 4) {
      window.localStorage.setItem('tour', 'true')
      this.dialogRef.close()
    }
  }

  skip() {
    window.localStorage.setItem('tour', 'true')
    this.dialogRef.close()
  }
}
