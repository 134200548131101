/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Page
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent implements OnInit {
  ref: any;
  errorText: any;
  errorType: number;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    this._activeRoute.params.subscribe((param) => {
      this.errorType = Number(param['type'])
    })
    this._activeRoute.queryParams.subscribe((param) => {
      this.ref = param['ref']
      this.errorText = param['error']
    })
  }

  redirect() {
    let url = new URL(window.location.href)
    url.searchParams.delete('accIndex')
    this._utilityService.goToLogin(url, 'replace')
  }
}
