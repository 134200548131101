import { Injectable } from '@angular/core';
import { HttpService } from '../core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(
    private _httpService: HttpService,
  ) { }

}
