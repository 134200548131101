/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category App
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, Inject, RendererFactory2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagingService } from './core/messaging/messaging.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sparkBase';
  renderer: any;

  constructor(rendererFactory: RendererFactory2,
    private _messaging: MessagingService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialogRef: MatDialog,
    private _router: Router) {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.dialogRef.closeAll();
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.titleService.setTitle((data.title ? data.title + " - " : '') + (data.portal ? data.portal + " | " : '') + environment.appName)
      });
    });
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }

    // Google analytics
    if (environment.production) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analyticsId;
      script.async = true;
      document.getElementsByTagName('head')[0].appendChild(script);
      const gtagEl = document.createElement('script');
      const gtagBody = document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config','`+ environment.analyticsId + `');
      `);
      gtagEl.appendChild(gtagBody);
      document.body.appendChild(gtagEl);
    }

  }

  receiveMessage: any = (event: any) => {
    if (event.origin == environment.loginDomain && event.data) {
      localStorage.setItem('accessToken', event.data.toString())
    }
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
