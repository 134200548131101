import { Injectable, Optional } from '@angular/core';
import { Messaging, onMessage, getToken, getMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, EMPTY, from, Observable, pipe } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../common-service/utility.service';
@Injectable({
  providedIn: 'root'
})

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  message$: Observable<any> = EMPTY;
  token$: Promise<any>;

  async tk$(): Promise<any> {
    const messaging = getMessaging();
    await getToken(messaging).then((currentToken) => {
      return Promise.resolve(currentToken)
    }).catch((error) => {
      return Promise.resolve(null)
    })
  }

  constructor(@Optional() messaging: Messaging,
    private _utility: UtilityService) {
    if (messaging && Notification.permission) {

      this.token$ = from(navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' }).
        then(serviceWorkerRegistration =>
          getToken(messaging, {
            serviceWorkerRegistration
          })
        )).pipe(
          tap(token => console.log('FCM', { token })),
          share(),
        ).toPromise()

      navigator.serviceWorker.onmessage = (event) => {
        if (event.data.data) {
          //console.log(event.data.data)
          this.currentMessage.next(event.data.data);
        }
      };

      onMessage(messaging, (payload: any) => {
        console.log('Message received. ', payload);
        const notification = new Notification(payload.notification?.title, { body: payload.notification.body, icon: 'assets/logo/splash-logo.png' });

      });
    }
  }
}
