import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Idle } from '@ng-idle/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/common-service/http.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { NotificationService } from 'src/app/core/messaging/notification.service';
import { UserService } from 'src/app/core/user/user.service';
import { getMessaging, getToken } from '@angular/fire/messaging';
import { LocationService } from '../location/location.service';
@Injectable()
export class AdminAuthService {
  apiUrl = environment.APIUrl;
  private _authenticated: boolean = false;
  deviceInfo;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _httpService: HttpService,
    private _utility: UtilityService,
    private idle: Idle,
    private deviceService: DeviceDetectorService,
    private _messagingService: MessagingService,
    private _notificationService: NotificationService,
    private _locationService: LocationService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  setAccessToken(token: string, refreshToken, username, userKey) {
    let key = this._utility.encrypt('businessAccessToken')
    let value = { token: token, refreshToken: refreshToken, username: username, userKey: userKey }
    sessionStorage.setItem(key, this._utility.encryptAES(JSON.stringify(value)).toString());
  }

  getAccessToken(): string {
    let key = this._utility.encrypt('businessAccessToken')
    return sessionStorage.getItem(key) ?? '';
  }

  set tenantId(id: string) {
    let key = this._utility.encrypt('business_tenant_id')
    sessionStorage.setItem(key, id);
  }

  get tenantId(): string {
    let key = this._utility.encrypt('business_tenant_id')
    return sessionStorage.getItem(key) ?? '';
  }


  /**
   * Sign in
   *
   * @param credentials
   */
  async signIn(data): Promise<any> {
    let request: any = {
      longitude: '',
      latitude: '',
      user_fcm_token: ''
    };

    this._locationService.getPosition().then((result: any) => {
      request.longitude = result.longitude
      request.latitude = result.latitude
    })

    await this._messagingService.token$.then((val) => {
      request.user_fcm_token = val;
    }).catch((error) => {
      return Promise.resolve(null)
    })

    request.username = data.username;
    if (data.login_type == 0)
      request.password = data.password
    else if (data.login_type == 1) {
      request.uid = data.uid
      request.provider_id = data.provider_id
      request.access_token = data.access_token
      request.email_id = data.email_id
    }
    request.browser_name = this.deviceInfo.browser
    request.browser_version = this.deviceInfo.browser_version
    request.os = this.deviceInfo.os
    request.os_version = this.deviceInfo.os_version
    request.device_type = '0'
    request.user_role_drc_key = 1
    return this._httpService.post(data.login_type == 0 ? 'external/auth/login' : 'external/auth/provider/login', request).pipe(
      switchMap((response: any) => {
        if (response.success == 1) {
          this._utility.welcomeUser(response.data.first_name)
          // Store the access token in the local storage
          this.setAccessToken(response.data.api_token, response.data.refresh_token, data.username, response.data.user_details_key);

          this.idle.watch()
          this._notificationService.start()
          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          //this._userService.user = response.data;
          if (response.data.tenant_id) {
            this.tenantId = response.data.tenant_id
          }
        }
        else {
          if (!environment.production) {
            console.log(response);
            //alert(JSON.stringify(response))
          }
        }
        return of(response);
      })
    ).toPromise();
  }

  /**
   * Sign out
   */
  signOut() {
    return new Promise((resolve, reject) => {
      let tokenData = JSON.parse(this._utility.decryptAES(this.getAccessToken()))
      this._httpService.postPartner('common/logout', { api_token: tokenData.token }, false)
        .subscribe((data: any) => {

          if (data.success == 1) {
            resolve(data)
            this._authenticated = false
          }
          else {
            reject(data)
          }
          this.idle.stop()
        }, (error) => {
          this._authenticated = false
          reject(error)
          this.idle.stop()
        });

    })

  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Promise<any> {

    // Check the access token availability
    if (this.getAccessToken()) {
      this.idle.watch()
      this._notificationService.start()
      return Promise.resolve(this.getAccessToken());
    }
    // If the access token exists and it didn't expire, sign in using it
    return Promise.resolve(false);
  }
}
