import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCartCount'
})
export class GetCartCountPipe implements PipeTransform {

  transform(value: unknown, ...args: any[]): any {
    let cart = localStorage?.getItem(decodeURIComponent(args[0]) + '-' + args[1] + '-cart')?.toString()
    console.log(decodeURIComponent(args[0]) + '-' + args[1] + '-cart');
    if (cart) {
      let cartData = JSON.parse(cart)
      return cartData.item_list.length > 0 ? cartData.item_list.length : ''
    }
    else
      return 0

  }

}
