<div class="flex h-full flex-col" *transloco="let t">
  <button mat-icon-button (click)="_dialog.dismiss()" class="text-white m-auto bg-gray-900">
    <mat-icon class="mat-18">close</mat-icon>
  </button>
  <svg width="100%" viewBox="0 0 390 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M114.506 0H40C17.9086 0 0 17.9086 0 40V657C0 679.091 17.9086 697 40 697H350C372.091 697 390 679.091 390 657V40C390 17.9086 372.091 0 350 0H277.995C272.816 0 267.687 1.00561 262.891 2.961L244.307 10.539C239.512 12.4944 234.383 13.5 229.204 13.5H162.93C157.656 13.5 152.434 12.4569 147.564 10.4306L129.872 3.06938C125.003 1.04314 119.78 0 114.506 0Z"
      fill="var(--spark-theme-black)" />
  </svg>

  <div class="bg-theme-black relative pb-3" style="min-height: 8rem;">
    <!-- Loader -->
    <ngx-ui-loader [loaderId]="'loader-03'" [hasProgressBar]="false" [overlayColor]="'rgba(40,40,40,0)'"
      [fgsColor]="'var(--spark-primary)'"></ngx-ui-loader>

    <!-- Menu item -->
    <ng-container>
      <div class="relative flex flex-col h-full px-6 pb-2 dark-component" [loaderId]="'loader-03'" ngxUiLoaderBlurred
        [blur]="5">

        <div class="flex flex-1 gap-y-2 flex-col h-full overflow-hidden m-auto w-full" *ngIf="order">
          <div class="header flex flex-row justify-between">
            <h5>{{order?.receipt_id}}</h5>
            <h5>
              {{order?.partner_business?.business_branch?.country?.currency_symbol}}{{order?.total_amount}}
            </h5>
          </div>
          <mat-divider panelClass="dark" class="my-3"></mat-divider>
          <div class="overflow-auto flex flex-col gap-2">
            <h6>{{t('user_menu.title.table_no')}} <span>{{order?.table_number??'NA'}}</span>
            </h6>
            <h6>{{t('user_menu.title.ordered_at')}} <span>{{order?.ordered_at}}</span>
            </h6>
            <h6>{{t('user_menu.title.ordered_items')}}</h6>
            <div role="list" class="overflow-auto">
              <div class="flex justify-between items-start order-item w-full "
                *ngFor="let item of order.order_item; let i=index">
                <div class="flex flex-col w-full">
                  <div>
                    <span class="text-base">{{item?.partner_food_item?.food_menu?.food_item}}
                      <ng-container *ngIf="item.variants.length>0">
                        <span class="text-sm">
                          ({{getVariants(item.variants)}})
                        </span>
                      </ng-container> x {{item.quantity}}
                    </span>
                    <div>
                      <ng-container *ngIf="item.addons.length>0">
                        <div class="flex items-center gap-2"> +
                          <div *ngFor="let addon of item.addons">
                            <span class="text-sm">
                              {{addon.partner_food_menu_addon.addon_name}} x {{addon.quantity}}
                            </span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mat-divider panelClass="dark" class="my-3"></mat-divider>
          <div class="flex dark">
            <button mat-button class="flex items-center justify-center opz-button" [disabled]="isDownloading"
              (click)="downloadReceipt()">
              <ng-container *ngIf="!isDownloading">
                <mat-icon class="icon-size-5 mr-2">file_download</mat-icon>
                <span>{{t('user_menu.title.download_invoice')}}</span>
              </ng-container>
              <div *ngIf="isDownloading" class="flex gap-2">
                <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
                <span>{{t('user_menu.message.please_wait')}}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>