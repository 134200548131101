/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Utility
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModelComponent implements OnInit {

  isLoading = true;
  isUploading = false;
  aspectRatio = 1 / 1;
  isRound = false
  scale: number = 1;
  transform: ImageTransform = {};
  constructor(
    public dialogRef: MatDialogRef<ImageCropperModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.image;
    if (this.data?.aspectRatio) this.aspectRatio = this.data?.aspectRatio
    if (this.data?.isRound) this.isRound = this.data?.isRound
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.isLoading = false;
    // show cropper
  }
  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    this.isLoading = false;
    // show message
  }

  setImage() {
    this.dialogRef.close(this.croppedImage)
  }

  zoomOut() {
    if (this.scale > 1) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
}
