import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-warning-strip',
  templateUrl: './warning-strip.component.html',
  styleUrls: ['./warning-strip.component.scss']
})
export class WarningStripComponent implements OnInit {

  @Input() text: any;
  @Input() color;
  @Input() type = 'single';
  @Input() title = 'Note';

  @Output() event = new EventEmitter<string>();;

  constructor() { }

  ngOnInit(): void {

  }

  isObject(val) {
    return typeof (val) == 'object'
  }

  eventCall(event) {
    this.event.emit(event)
  }
}
