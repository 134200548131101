/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Modal
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PartnerService } from 'src/app/components/partner/partner.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';

@Component({
  selector: 'app-check-menu-type-modal',
  templateUrl: './check-menu-type-modal.component.html',
  styleUrls: ['./check-menu-type-modal.component.scss']
})
export class CheckMenuTypeModalComponent implements OnInit {
  foodMenuTypes: any[] = []

  isLoading

  foodMenuTypeForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CheckMenuTypeModalComponent>,
    private partnerService: PartnerService,
    private _utility: UtilityService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    // Menu type form
    this.foodMenuTypeForm = this._fb.group({
      'menu_type_list': this._fb.array([], Validators.required)
    })

    // Get menu types
    this.partnerService.getCommonFoodMenuTypeList().then((data: any) => {
      this.foodMenuTypes = data
      for (let type of this.foodMenuTypes) {
        this.getMenuTypeList().push(this._fb.group({
          'menu_type': type?.menu_type,
          'food_menu_type_key': type.food_menu_type_key
        }))
      }
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Form methods
  // -----------------------------------------------------------------------------------------------------

  getMenuTypeList(): FormArray {
    return this.foodMenuTypeForm.get('menu_type_list') as FormArray
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  /**
   * Add menu types
   */
  setMenuType(menuType, event) {
    if (event.checked)
      this.getMenuTypeList().push(this._fb.group({
        'menu_type': menuType.menu_type,
        'food_menu_type_key': menuType.food_menu_type_key
      }))
    else {
      let index = this.getMenuTypeList().controls.findIndex((ctrl) => ctrl.get('food_menu_type_key')?.value == menuType.food_menu_type_key)
      this.getMenuTypeList().removeAt(index)
    }
  }

  /**
   * Add menu type
   */
  addFoodMenuType() {
    this.isLoading = true;
    this.partnerService.addFoodMenuType(this.foodMenuTypeForm.value).then((data: any) => {
      this.dialogRef.close('success')
      location.reload()
    }).catch((error) => {
      if (error.status_code == 608) {
        for (var key in error.data) {
          let obj = key.split(".", 3)
          let message = ''
          if (obj[0] == "menu_type_list" && this.getMenuTypeList().length > 0) {
            message = error.data[key][0].replace(key, this.getMenuTypeList().controls[obj[1]].get('menu_type').value)
          }
          else {
            message = error.data[key][0]
          }
          this.getMenuTypeList().setErrors({ serverType: { message: this._utility.toProperCase(message) } })
          this.getMenuTypeList().markAllAsTouched()
        }
      }
    })
      .finally(() => {
        this.isLoading = false;
      })
  }

  checkMenuType(menuType) {
    return this.getMenuTypeList().controls.findIndex((ctrl) => ctrl.get('food_menu_type_key')?.value == menuType.food_menu_type_key)
  }
}
