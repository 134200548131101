import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuLayoutComponent } from './menu-layout.component';
import { RouterModule } from '@angular/router';
import { MenuLayoutService } from './menu-layout.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatBadgeModule } from '@angular/material/badge';
import { UserAuthService } from 'src/app/core/user-auth/user-auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { PipeModule } from 'src/app/components/pipes/pipe.module';

@NgModule({
  declarations: [
    MenuLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    SharedModule,
    MatMenuModule,
    PipeModule
  ],
  exports: [
    MenuLayoutComponent
  ],
  providers: [
    { provide: 'HOTELDATA', useClass: MenuLayoutService },
    UserAuthService
  ]
})
export class MenuLayoutModule { }
