import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenStateService {
  subject = new BehaviorSubject(true);

  constructor() {

  }

  start() {
    this.subject.next(true);
  }
  stop() {
    this.subject.next(false);
  }

}
