import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, forkJoin, map, mergeMap, Observable, Subscription } from 'rxjs';
import { SparkNavigationService } from 'src/@spark/components/navigation/navigation.service';
import { MenuCardService } from 'src/app/components/menu/menu-card.service';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { HttpService } from 'src/app/core/common-service/http.service';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { SubscriptionService } from 'src/app/core/subscription/subscription.service';
import { UserService } from 'src/app/core/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService implements Resolve<any>
{
  hotelSubscription = new BehaviorSubject(null);
  hotelData: any;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _httpService: HttpService,
    private _subscriptionService: SubscriptionService,
    private splashScreenStateService: SplashScreenStateService,
  ) {
    this.hotelSubscription.subscribe(data => {
      this.hotelData = data
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._userService.getBusiness().pipe(
        mergeMap(menus => this._navigationService.getAllMenus()),
        mergeMap(subscription => {
          return this._subscriptionService.get().pipe(
            map((data) => {
              this.splashScreenStateService.start()
              this.getHotelById(data.data?.partner_business_key).then((data: any) => {
                this.hotelSubscription.next(data)
                setTimeout(() => {
                  this.splashScreenStateService.stop()
                }, 2000);

              })
            }))
        }
        ),
      )
      // this._userService.getMenuAccess()
    ]);
  }

  getHotelById(businessKey) {
    let params = new HttpParams();
    params = params.set('partner_business_key', businessKey);
    params = params.set('is_basic', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner("owner/business/getBusiness?" + params).subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Notifications
  // -----------------------------------------------------------------------------------------------------

  getNotifications(page, status?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    if (limit)
      params = params.set('limit', limit);
    if (status == 0 || status == 1)
      params = params.set('notification_status', status);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('common/notification/getAll?' + params, false).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  markAllRead() {
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("common/notification/changeStatus", { notification_status: 1, is_all: true }, false).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  markRead(key, notificationStatus) {
    notificationStatus = !notificationStatus
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("common/notification/changeStatus", { notification_key: key, notification_status: notificationStatus }, false).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  removeNotification(key) {
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("common/notification/changeStatus", { notification_key: key, is_deleted: 1 }, false).subscribe((data: any) => {

        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  removeAllNotification() {
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("common/notification/changeStatus", { is_deleted: 1, is_all: 1 }, false).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  registerOwnerFCM(token) {
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("common/user/registerFCM", { user_fcm_token: token }, false).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Share links
  // -----------------------------------------------------------------------------------------------------

  getShareLinks() {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/shareable/getLink?' + params).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  addShareLink() {
    let data = { 'business_branch_key': this.hotelData.business_branch.business_branch_key }
    return new Promise((resolve, reject) => {
      this._httpService.postPartner("owner/shareable/addLink", data).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }


}
