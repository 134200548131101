import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'getFoodAvailability'
})
export class GetFoodAvailabilityPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any {
    if (value) {
      let nextDay = value?.find((workDay) =>
      (this.getDayNo(workDay.working_day) > new Date().getDay()
        || (this.getDayNo(workDay.working_day) == new Date().getDay() && this.getNextWorkingHour(workDay))
        || (new Date().getDay() == 6 && this.getDayNo(workDay.working_day) == 0))
      ) ?? value[0]

      let isTodayActive = this.getDayNo(nextDay.working_day) == new Date().getDay() && this.getNextWorkingHour(nextDay)

      return 'Next available on ' +
        ((this.getDayNo(nextDay.working_day) == new Date().getDay() + 1) ?
          'tommorow ' :
          (isTodayActive ? '' : nextDay.working_day)) + " " +
        (isTodayActive ? this.getNextWorkingHour(nextDay).open : nextDay.working_hours[0].open);
    }
  }

  getDayNo(day) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].findIndex((dayItem) => dayItem == day)
  }

  getNextWorkingHour(nextDay) {
    return nextDay.working_hours.find((workHour) => {
      let close = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-us') + " " + workHour?.close)
      var given = moment(close);
      var current = moment();
      if (current < given) {
        return workHour;
      }
      return ''
    })
  }

}
