import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationModule } from './components/navigation/navigation.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SparkConfirmationModule } from './services/confirmation/confirmation.module';
import { TailwindService } from './services/tailwind.service';
import { SparkSplashScreenModule } from './services/splash-screen/splash-screen.module';
import { DirectivesModule } from './directives/directives.module';
import { RouteSplashScreenStateService } from './services/route-splash-screen-state.service';
import { WarningStripComponent } from './components/warning-strip/warning-strip.component';

@NgModule({
  declarations: [
    WarningStripComponent
  ],
  providers: [
    TailwindService,
    RouteSplashScreenStateService
  ],
  imports: [
    CommonModule,
    NavigationModule,
    MatIconModule,
    MatDialogModule,
    SparkConfirmationModule,
    //IdleSessionModule,
    SparkSplashScreenModule,
    DirectivesModule
  ],
  exports: [
    NavigationComponent,
    WarningStripComponent
  ]
})
export class SparkModule { }
