import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SparkModule } from 'src/@spark/spark.module';
import { SPARK_APP_CONFIG } from 'src/@spark/services/config/config.constants';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SparkConfigModule } from 'src/@spark/services/config/config.module';
import { sparkConfig } from './core/config/app.config';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpIntercept } from './core/common-service/http-intercept';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { RouteSplashScreenStateService } from 'src/@spark/services/route-splash-screen-state.service';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PagesModule } from './components/pages/pages.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    NgIdleKeepaliveModule.forRoot(),
    CoreModule,
    BrowserAnimationsModule,
    SparkConfigModule.forRoot(sparkConfig),
    SparkModule,
    SharedModule,
    LayoutModule,
    HttpClientModule,
    NgxUiLoaderRouterModule,
    SweetAlert2Module.forRoot(),
    ScrollingModule,
    MatBottomSheetModule,
    provideMessaging(() => getMessaging()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    RouteSplashScreenStateService,
    { provide: 'config', useValue: SPARK_APP_CONFIG },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercept,
      multi: true
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer,
    library: FaIconLibrary,
    private _sparkConfigService: SparkConfigService) {
    library.addIconPacks(fas, fab);
    this._sparkConfigService.config$.subscribe((data: any) => {
      const config = data
      if (config.scheme == 'dark') {
        overlayContainer.getContainerElement().classList.add('dark');
      }
      else {
        overlayContainer.getContainerElement().classList.remove('dark');
      }
    })
  }

}


