/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Sheet
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuLayoutService } from 'src/app/layout/layouts/menu-layout/menu-layout.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MenuCardService } from '../../menu-card.service';
import * as CryptoJS from 'crypto-js';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-single-order-sheet',
  templateUrl: './single-order-sheet.component.html',
  styleUrls: ['./single-order-sheet.component.scss']
})
export class SingleOrderSheetComponent implements OnInit {
  orderKey: any;
  order;
  hotelData: any;

  hasError: boolean;
  isDownloading = false
  successKey = CryptoJS.SHA256("success");
  failureKey = CryptoJS.SHA256("failure");

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _activeRoute: ActivatedRoute,
    private menuCardService: MenuCardService,
    private splashScreenStateService: SplashScreenStateService,
    private menuLayoutService: MenuLayoutService,
    private ngxUiService: NgxUiLoaderService,
    private _utility: UtilityService,
    private router: Router,
    public _dialog: MatBottomSheetRef<SingleOrderSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public modalData: any,
  ) {
    // Get hotel data
    menuLayoutService.hotelData.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(data => {
        this.hotelData = data
      }, (error) => {
        this.hasError = true
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Get order by id
    this.ngxUiService.startLoader('loader-03')
    this.menuCardService.getOrderById(this.modalData.orderKey).then((data: any) => {
      this.hasError = false
      this.order = data
    }).finally(() => {
      setTimeout(() => {
        this.splashScreenStateService.stop()
      }, 2000);
      this.ngxUiService.stopLoader('loader-03')
    }).catch((error) => {
      this.hasError = true
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Download receipt
   */
  downloadReceipt() {
    this.isDownloading = true
    //this._utility.showProcess('Downloading invoice...', 'invoice')
    this.menuCardService.downloadReceipt(this.order.order_key).then((data: any) => {
      let blob = new Blob([data.data], {
        type: 'application/pdf'
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'
      link.download = this.order.receipt_id + '.pdf';
      link.click();
      window.URL.revokeObjectURL(link.href);
    }).finally(() => {
      this._utility.removeProcess('invoice')
      this.isDownloading = false
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Utility
  // -----------------------------------------------------------------------------------------------------
  getSubtotalPrice(i) {
    let total = 0
    let addonTotal = 0
    this.order.order_item[i].addons.forEach(addon => {
      addonTotal += addon.unit_price
    });
    let variantTotal = 0
    if (this.order.order_item[i].variants.length > 0) {
      this.order.order_item[i].variants.forEach(variant => {
        variantTotal += variant.unit_price
      });
    }
    total = ((addonTotal + variantTotal) + Number(this.order.order_item[i].unit_price)) * this.order.order_item[i].quantity
    return total
  }

  getVariants(variants) {
    return variants.map(s => s.partner_food_menu_variant.variant_name).join(', ')
  }

  getAddons(addons) {
    return addons.map(s => s.partner_food_menu_addon.addon_name)
  }

}
