/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Utility
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-upload-snackbar',
  templateUrl: './file-upload-snackbar.component.html',
  styleUrls: ['./file-upload-snackbar.component.scss']
})
export class FileUploadSnackbarComponent implements OnInit {
  progress = 0;
  constructor() {

  }

  ngOnInit(): void {

  }

}
