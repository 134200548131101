import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { navigation } from 'src/app/navigation';
import { HttpService } from '../common-service/http.service';
import { UserService } from '../user/user.service';
@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private onLoadNavigation: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userData: any;
    constructor(private _httpService: HttpService,
        private userService: UserService) {
        this.userService.user$.pipe(
            takeUntil(this._unsubscribeAll)).subscribe((data) => {
                this.userData = data
            })
    }

    get navigation$(): Observable<any> {
        return this.onLoadNavigation.asObservable();
    }

    getAllMenus() {
        return this._httpService.getPartner('owner/features/access/getAll').pipe(
            tap((data: any) => {
                this.onLoadNavigation.next(data.data)
            }))
    }
}
