<div matRipple class="nav-item parent" [matTooltipClass]="'custom-mat-tooltip right'" [matTooltipPosition]="'right'"
  [matTooltip]="item.is_locked?'Renew to upgraded subscription':''" [class.submenu]="isSubmenu"
  *ngIf="!item.is_redirected" [routerLink]="!item.is_locked?'partner/'+item.link:null" [routerLinkActive]="'active'"
  [routerLinkActiveOptions]="isActiveMatchOptions">
  <div class="flex items-center">
    <mat-icon *ngIf="item.icon_type=='mat'" class="nav-icon material-icons-outlined">{{item.icon}}</mat-icon>
    <fa-icon *ngIf="item.icon_type!='mat'" class="nav-icon" [icon]="[item.icon_type, item.icon]"></fa-icon>
    <span>{{item.title}}</span>
    <div [class.lock-overlay]="isSidebarFold && item.is_locked" class="inline-flex">
      <mat-icon *ngIf="item.is_locked" class="icon icon-size-5" [class.ml-2]="!isSidebarFold">
        lock
      </mat-icon>
    </div>
  </div>
</div>
<div matRipple class="nav-item parent" [matTooltipClass]="'custom-mat-tooltip right'" [matTooltipPosition]="'right'"
  [matTooltip]="item.is_locked?'Renew to upgraded subscription':''" [class.submenu]="isSubmenu"
  *ngIf="item.is_redirected" (click)="!item.is_locked?redirectTo(item.redirect_url):null" [routerLinkActive]="'active'"
  [routerLinkActiveOptions]="isActiveMatchOptions">
  <div class="flex items-center">
    <mat-icon *ngIf="item.icon_type=='mat'" class="nav-icon material-icons-outlined">{{item.icon}}</mat-icon>
    <fa-icon *ngIf="item.icon_type!='mat'" class="nav-icon" [icon]="[item.icon_type, item.icon]"></fa-icon>
    <span>{{item.title}}</span>
    <div [class.lock-overlay]="isSidebarFold && item.is_locked" class="inline-flex align-middle">
      <mat-icon *ngIf="item.is_locked" class="icon icon-size-5" [class.ml-2]="!isSidebarFold">
        lock
      </mat-icon>
    </div>
    <mat-icon *ngIf="!item.is_locked && !isSidebarFold" class=" icon-size-5 ml-2">open_in_new</mat-icon>
  </div>
</div>