/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Page
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/messaging/notification.service';
import { AdminLayoutService } from '../admin-layout.service';
import moment from 'moment';

export class Notification {
  'today': {
    day: 'Today',
    data: any[]
  };
  'yesterday': {
    day: 'Yesterday',
    data: any[]
  };
  'earlier': {
    day: 'Earlier',
    data: any[]
  };
}

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  notifications: Notification = {
    'today': {
      day: 'Today',
      data: []
    },
    'yesterday': {
      day: 'Yesterday',
      data: []
    },
    'earlier': {
      day: 'Earlier',
      data: []
    }
  };
  isLoading: boolean;
  hasError: boolean;
  lastPage: boolean = false;
  pagination = {
    length: 0,
    page: 0,
    size: 10
  }

  asIsOrder(a, b) {
    return 1;
  }

  constructor(
    public dialogRef: MatDialogRef<AllNotificationsComponent>,
    private _adminLayoutService: AdminLayoutService,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.getNotifications(0)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Notifications
  // -----------------------------------------------------------------------------------------------------

  getNotifications(page, size = this.pagination.size, status = false) {
    this.isLoading = true;
    return this._adminLayoutService.getNotifications(page + 1, null, size).then((data: any) => {
      if (data) {
        this.notifications.today.data.push(...data.data.filter((notification) => moment(notification.notified_at).isSame(new Date(), 'day')))

        this.notifications.yesterday.data.push(...data.data.filter((notification) => moment(notification.notified_at).isSame(moment().subtract(1, 'day'), 'day')))

        this.notifications.earlier.data.push(...data.data.filter((notification) => moment(notification.notified_at).isBefore(moment().subtract(1, 'day'), 'day')))

        this.pagination = {
          length: data.total,
          page: page,
          size: size
        }
        if (data.to == data.total) {
          this.lastPage = true
        }
      }
      else {
        if (page == 0) {
          this.notifications.today.data = []
          this.notifications.earlier.data = []
          this.notifications.yesterday.data = []
        }
        this.pagination = {
          length: 0,
          page: 0,
          size: size
        }
      }
    }).catch((error) => {
      this.hasError = true
      this.isLoading = false
    }).finally(() => {
      this.isLoading = false
    })
  }

  loadMore() {
    this.getNotifications(this.pagination.page + 1, 10)
  }

  markRead(key, notificationStatus, object, index) {
    this._adminLayoutService.markRead(key, notificationStatus).then((data) => {
      this.notifications[object].data[index].notification_status = !notificationStatus
      //this.getNotifications(this.pagination.page)
    })
  }

  removeNotification(key, object, index) {
    this._adminLayoutService.removeNotification(key).then((data) => {
      this.notifications[object].data.splice(index, 1)
    })
  }

  removeAllNotifications() {
    this._adminLayoutService.removeAllNotification().then((data) => {
      this.getNotifications(0).then(() => {
        if (this.notifications['today'].data.length == 0
          && this.notifications['yesterday'].data.length == 0
          && this.notifications['earlier'].data.length == 0) this.dialogRef.close()
      })
    })
  }


}
