/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, Renderer2, RendererFactory2, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { lookupService } from 'dns';
import { Subject, takeUntil } from 'rxjs';
import { AdminAuthService } from 'src/app/core/admin-auth/admin-auth.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { UserAuthService } from 'src/app/core/user-auth/user-auth.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss']
})
export class LandingLayoutComponent implements OnInit {
  renderer: any;
  @Input() isPartner = false
  orgURL = environment.orgURL
  isActiveMatchOptions: any;
  userData;
  partnerData;
  accountList: any = []

  isMenuShow = false
  isPageLoad = false

  currentDate = Date.now()

  personalSecreteKey = "personalSecret";
  businessSecreteKey = this._utility.encrypt("businessSecret");
  personalKey = this._utility.encrypt("personal")
  businessKey = this._utility.encrypt("business")

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let navElement = document.querySelector('.header') as HTMLElement;
    let scrollTopElement = document.querySelector('.scroll-top-btn') as HTMLElement;
    if (window.pageYOffset > navElement.clientHeight) {
      navElement.classList.add('header-shrink');
      scrollTopElement.classList.add('active');
    } else {
      navElement.classList.remove('header-shrink');
      scrollTopElement.classList.remove('active');
    }
  }

  constructor(
    rendererFactory: RendererFactory2,
    private overlayContainer: OverlayContainer,
    private _userService: UserService,
    private _utility: UtilityService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _userAuthService: UserAuthService,
    private _adminAuthService: AdminAuthService,
    @Inject(DOCUMENT) private _document: any,
    private renderer2: Renderer2,
    private titleService: Title,) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  ngOnInit(): void {
    if (!(window.hasOwnProperty('Tawk_API') && (Object.keys(window['Tawk_API']).length != 0))) {
      const s = this.renderer2.createElement('script');
      s.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/`+ environment.tawkPropertyId + `/` + environment.tawkWidgetId + `';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();`
      this.renderer2.appendChild(document.body, s);
    }
    else
      window['Tawk_API'].showWidget()

    let creds = this._utility.decryptAES(this._utility.getCookie(this.personalSecreteKey))
    if (creds) {
      let credData = JSON.parse(creds)
      this._activatedRoute.queryParams.subscribe((params) => {
        if (params['accIndex']) {
          var refreshUrl = new URL(window.location.href);
          refreshUrl.searchParams.delete("accIndex");
          if (credData) {
            if (credData[params['accIndex']].role == 2)
              this._userAuthService.signIn(credData[params['accIndex']]).then((res) => {
                if (res.status_code == 200) {
                  let url = refreshUrl.pathname + refreshUrl.search
                  this._router.navigateByUrl(url, { replaceUrl: true })
                  this.getProfile()
                }
              });

            if (credData[params['accIndex']].role == 1)
              this._adminAuthService.signIn(credData[params['accIndex']]).then((res) => {
                if (res.status_code == 200) {
                  let url = refreshUrl.pathname + '/become-a-partner'
                  this._router.navigateByUrl(url, { replaceUrl: true })
                  this.getProfile()
                }
              });

          }
        }
      })
    }

    // 1. Get user data
    // 2. Get account list from cookies
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        if (data.user_login?.user_role_name?.user_role_drc_key == 2)
          this.userData = data
        else
          this.partnerData = data
      })

    document.getElementById('appFavicon')?.setAttribute('href', 'assets/favicon.ico');
    this.isActiveMatchOptions = {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact'
    };
    this._updateTheme()
    this._updateScheme()
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * After view load
   */
  ngAfterViewInit() {

    this._document.body.classList.add('spark-splash-screen-hidden');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isPartner) {
      this.isPartner = changes.isPartner.currentValue
      this.getProfile()
    }
  }

  /**
  * Go to SPARK profile
  */
  goToMyAccount() {
    if ((!this.isPartner && this.userData) || (this.isPartner && this.partnerData)) {
      let credData = this._utility.getSecret("personalSecret")
      let index = credData.findIndex((acc) => acc.username == (this.isPartner ? this.partnerData?.user_login?.username : this.userData?.user_login?.username) && acc.role == (this.isPartner ? 1 : 2))
      const url = new URL(environment.myaccountDomain);
      url.searchParams.set('accIndex', index);
      window.open(url, '_blank')
    }
    else {
      this._utility.goToLogin((environment.emenuDomain + (this.isPartner ? "/partner/" : "")), 'self')
    }
  }

  /**
   * Go to partner dashboard
   */
  goToDashboard() {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.partnerData?.user_login?.username && acc.role == 1)
    const url = new URL(environment.emenuDomain + "/partner/");
    url.searchParams.set('accIndex', index);
    window.open(url)
  }

  /**
   * Get profile
   */
  getProfile() {
    let credData = this._utility.getSecret("personalSecret")
    this.userData = null
    this.partnerData = null

    if (!this.isPartner) {
      let key = this._utility.encrypt('userAccessToken')
      let localToken = this._utility.getLocalSession(key)
      if (!credData || !credData.find((cred) => cred.username == localToken.username)) {
        sessionStorage.removeItem(this._utility.encrypt('userAccessToken'))
        sessionStorage.removeItem(this._utility.encrypt('personal_tenant_id'))
      }
      else {
        if (localToken) this._userService.getPersonal().subscribe()
      }
    }
    else {
      let key = this._utility.encrypt('businessAccessToken')
      let localToken = this._utility.getLocalSession(key)
      if (!credData || !credData.find((cred) => cred.username == localToken.username)) {
        sessionStorage.removeItem(this._utility.encrypt('businessAccessToken'))
        sessionStorage.removeItem(this._utility.encrypt('business_tenant_id'))
      }
      else
        if (localToken) this._userService.getBusiness().subscribe()
    }
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    //this._document.body.classList.add(`theme-blue`);
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('dark');
    this._document.body.classList.add('light');
    this.overlayContainer.getContainerElement().classList.remove('dark');
    this.overlayContainer.getContainerElement().classList.add('light');
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
