import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, filter, Subject, Subscription, take } from 'rxjs';

@Injectable()
export class RouteSplashScreenStateService {
  subject = new BehaviorSubject(true);

  constructor(
    private router: Router
  ) {

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        console.log('pp');

        this.stop()
        //this.hide();
      });
  }

  start() {
    this.subject.next(true);
  }
  stop() {
    this.subject.next(false);
  }

}
