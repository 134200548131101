<div class="flex h-full w-full items-center justify-center flex-col mb-6" *transloco="let t">
  <img width="550"
    [src]="errorType==452?'assets/illustrations/insufficient-permission.png':'assets/illustrations/user-insufficient-permission.png'">
  <h3 class="mt-12 text-5xl sm:text-4xl text-center">{{errorText}}</h3>
  <ng-container>
    <p class="mt-6 text-xl secondary-text">
      {{t('auth.actions.please_login_with_your_account',{type:errorType==452?'business':'personal'})}}</p>
    <button mat-stroked-button [color]="'primary'" class="mt-6 add-round-button" (click)="redirect()">
      {{t('auth.actions.back_to_login')}}</button>
  </ng-container>
</div>