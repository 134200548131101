import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServerDownComponent } from './auth-pages/server-down/server-down.component';
import { HomeComponent } from './components/home/home/home.component';
import { MenuCardHomeComponent } from './components/menu/menu-card-home/menu-card-home.component';
import { QrScannerComponent } from './components/menu/qr-scanner/qr-scanner.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { AdminAuthGuard } from './core/admin-auth/guards/admin-auth.guard';
import { AdminLayoutService } from './layout/layouts/admin/admin-layout.service';
import { MenuLayoutComponent } from './layout/layouts/menu-layout/menu-layout.component';
import { MenuLayoutService } from './layout/layouts/menu-layout/menu-layout.service';
import { NoPermissionComponent } from './components/pages/no-permission/no-permission.component';
import { NoSubscriptionComponent } from './components/pages/no-subscription/no-subscription.component';
import { MyOrdersComponent } from './components/menu/my-orders/my-orders.component';
import { SingleOrderComponent } from './components/menu/single-order/single-order.component';
import { UserAuthGuard } from './core/user-auth/guards/user-auth.guard';
const routes: Routes = [
  {
    path: 'partner',
    data: {
      layout: 'partner',
      portal: 'Partner Portal'
    },
    resolve: { initialData: AdminLayoutService },
    loadChildren: () => import('./components/partner/partner.module').then(m => m.PartnerModule),
  },
  {
    path: 'menu/:partnerName',
    data: {
      layout: 'menu'
    },
    children: [
      {
        path: '',
        resolve: { hotelData: MenuLayoutService },
        data: {
          showBottomNav: false
        },
        component: MenuCardHomeComponent
      },
      {
        path: ':menuType',
        resolve: { hotelData: MenuLayoutService },
        loadChildren: () => import('./components/menu/menu-card.module').then(m => m.MenuCardModule),
      },

    ]
  },
  {
    path: '',
    data: {
      layout: 'landing'
    },
    loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    data: {
      layout: 'menu'
    },
    canActivate: [UserAuthGuard],
    children: [
      {
        path: 'my-orders',
        data: {
          showBottomNav: false
        },
        component: MyOrdersComponent
      }
    ]
  },
  {
    path: 'server-down',
    component: ServerDownComponent,
    data: {
      layout: 'empty'
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
    data: {
      layout: 'empty'
    },
  },
  {
    path: 'no-permission/:type',
    component: NoPermissionComponent,
    data: {
      layout: 'empty'
    },
  },
  {
    path: 'no-subscription',
    component: NoSubscriptionComponent,
    data: {
      layout: 'empty'
    },
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
