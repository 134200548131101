import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpService } from '../common-service/http.service';
import { environment } from 'src/environments/environment';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  apiURL = environment.APIUrl;
  private _subscription: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Constructor
   */
  constructor(private _httpService: HttpService, private ability: Ability) {

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set subscription(value: any) {
    // Store the value
    this._subscription.next(value);
  }

  get subscription$(): Observable<any> {
    return this._subscription.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get() {
    return this._httpService.getPartner('owner/subscription/getLevel').pipe(
      tap((data: any) => {
        if (data.status_code == 200) {
          this._subscription.next(data.data);
          this.updateAbility(data.data.level)
        }
      })
    );

  }

  /**
   * Update the user
   *
   * @param user
   */
  update() {
    return this._httpService.getPartner('owner/subscription/getLevel').subscribe((data: any) => {
      if (data.status_code == 200) {
        this._subscription.next(data.data);
        this.updateAbility(data.data.level)
      }
    })
  }

  private updateAbility(data) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);

    if (data <= 1) {
      can('manage', 'basic')
    }
    else if (data <= 2) {
      can('manage', 'medium')
    }
    else if (data <= 3) {
      can('manage', 'premium')
    }
    this.ability.update(rules);
  }


}
