import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpService } from '../common-service/http.service';
import { environment } from 'src/environments/environment';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiURL = environment.APIUrl;
  private _user: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _subscription: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Constructor
   */
  constructor(private _httpService: HttpService, private ability: Ability) {

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: any) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<any> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  getPersonal() {
    return this._httpService.getExternalPersonal('external/common/user/getProfile?is_basic=1').pipe(
      tap((data: any) => {
        if (data.data)
          this._user.next(data.data);
      })
    );
  }

  getBusiness() {
    return this._httpService.getExternalBusiness('external/common/user/getProfile?is_basic=1').pipe(
      tap((data: any) => {
        if (data.data)
          this._user.next(data.data);
      })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */

  getMenuAccess() {
    //console.log(localStorage.getItem('user_details_key'));

    return new Promise((resolve, reject) => {
      this._httpService.getPartner('admin/user/menu/accessList?user_details_key=' + this.user.user_details_key).subscribe((data: any) => {
        this.updateAbility(data.data)
        resolve(data.data);
      }, (error) => {
        reject(error)
      })
    })
  }

  private updateAbility(data) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);

    if (data?.menu_list) {
      data.menu_list.forEach(menu => {
        //can('manage', menu.id)
        if (menu.is_selected == 0) {
          cannot('read', menu.id)
        }
        else if (menu.access_level == 0) {
          can('read', menu.id)
        }
        else if (menu.access_level == 1) {
          can('manage', menu.id)
        }
        menu.children_menu.forEach(submenu => {
          //can('manage', submenu.id)
          if (submenu.is_selected == 0) {
            cannot('read', submenu.id)
          }
          else if (submenu.access_level == 0) {
            can('read', submenu.id)
          }
          else if (submenu.access_level == 1) {
            can('manage', submenu.id)
          }
        });
      });
    }
    this.ability.update(rules);
  }



}
