import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { SparkConfirmationService } from 'src/@spark/services/confirmation/confirmation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { TranslocoService } from '@ngneat/transloco';
import { formatDate } from '@angular/common';
import { Observable, combineLatest, debounceTime, map, shareReplay } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  deleteConfigForm: FormGroup
  constructor(private _http: HttpClient,
    private _fb: FormBuilder,
    private _fuseConfirmationService: SparkConfirmationService,
    private cookieService: CookieService,
    private _translocoService: TranslocoService,
    private _router: Router,
    private _toaster: ToastrService) {

  }

  get razorpayConfig() {
    return {
      "modal": {
        "ondismiss": function () { },
        "confirm_close": true,
        "escape": false
      },
      config: {
        display: {
          hide: [
            {
              method: "cardless_emi"
            },
            {
              method: "paylater"
            },
            {
              method: "emi"
            },
            {
              method: "wallet"
            },
          ],
        }
      }
    }
  }

  scrollByFormName(name) {
    let el = document.querySelector('[formControlName="' + name + '"]');
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  scrollById(name) {
    let el = document.getElementById(name);
    el?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  }

  openPdf(data) {
    var file = new Blob([data], { type: 'application/pdf' });
    var fileURL = window.URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    //link.setAttribute('download', data.filename);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  encrypt(data) {
    let key = environment.isDebug ? data : CryptoJS.SHA256(data).toString();
    return key;
  }

  decryptAES(data) {
    try {
      return environment.isDebug ? data : CryptoJS.AES.decrypt(data, environment.passphrase).toString(CryptoJS.enc.Utf8)
    } catch (error) {
      this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
  }

  encryptAES(data) {
    let key = environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()
    return key;
  }

  clearSession() {
    sessionStorage.clear()
    this.cookieService.deleteAll('/', environment.domain)
  }

  getSecret(name) {
    let key = this.encrypt(environment.env + "-" + name)
    try {
      if (this.cookieService.get(key))
        return JSON.parse(environment.isDebug ? this.cookieService.get(key) : CryptoJS.AES.decrypt(this.cookieService.get(key), environment.passphrase).toString(CryptoJS.enc.Utf8))
    }
    catch (error) {
      this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
    return null;
  }

  getProfileSecret(name) {
    try {
      let key = this.encrypt(environment.env + "-" + name)
      return environment.isDebug ? this.cookieService.get(key) : CryptoJS.AES.decrypt(this.cookieService.get(key), environment.passphrase).toString(CryptoJS.enc.Utf8)
    } catch (error) {
      this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
  }

  removeProfileSecret(name) {
    try {
      this.clearCookie(name)
    } catch (error) {
      this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
  }

  getLocalSession(name) {
    try {
      return sessionStorage.getItem(name) ?
        JSON.parse(environment.isDebug ? sessionStorage.getItem(name) : CryptoJS.AES.decrypt(sessionStorage.getItem(name), environment.passphrase).toString(CryptoJS.enc.Utf8))
        : ''
    } catch (error) {
      this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
  }

  setCookie(name, data) {
    let key = environment.isDebug ? environment.env + '-' + name : CryptoJS.SHA256(environment.env + '-' + name);
    var now = new Date();
    let devExp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
    let prodExp = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15);
    this.cookieService.set(
      key,
      (environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()),
      { domain: environment.domain, expires: environment.production ? prodExp : devExp, path: '/' })
  }

  removeSession(username, role) {
    let accountList: any = this.getAccounts(role)
    let index = accountList.findIndex((acc) => acc.role == role && acc.username == username)
    if (index > -1) {
      accountList.splice(index, 1)
      this.removeProfileSecret(username + "_" + "profile_token")
      if (accountList.length == 0)
        this.clearCookie("personalSecret")
      else
        this.setCookie("personalSecret", JSON.stringify(accountList))
      if (role == 2) {
        sessionStorage.removeItem(this.encrypt('userAccessToken'))
        sessionStorage.removeItem(this.encrypt('personal_tenant_id'))
      }
      else if (role == 1) {
        sessionStorage.removeItem(this.encrypt('businessAccessToken'))
        sessionStorage.removeItem(this.encrypt('business_tenant_id'))
      }
    }
  }

  getAccounts(role) {
    let accountList: any[] = []
    let accData = JSON.parse(this.decryptAES(this.getCookie("personalSecret")))
    for (let user of accData) {
      try {
        if (user.role == role)
          accountList.push(user)
      }
      catch (e) {
        // return false;
      }
    }
    return accountList;
  }

  clearCookie(name) {
    let key = this.encrypt(environment.env + "-" + name);
    this.cookieService.delete(key, '/', environment.domain)
  }

  getCookie(name) {
    let key = this.encrypt(environment.env + '-' + name);
    return this.cookieService.get(key)
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  toFormData(data, form, name?) {
    if (typeof data === 'object' && data != undefined) {
      for (let key in data) {
        if (data[key]) {
          if (typeof data[key] === 'object' && data[key] != null && !(data[key] instanceof File)) {
            if (name)
              this.toFormData(data[key], form, name + '[' + key + ']')
            else {
              this.toFormData(data[key], form, key)
            }
          }
          else {
            if (!name)
              form.append(key, data[key]);
            else
              form.append(name + '[' + key + ']', data[key]);
          }
        }

      }
    }
    else {
      form.append(name, data);
    }

    return form;
  }

  getFileName(path) {
    return path.replace(/^.*[\\\/]/, '');
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data['breadcrumb'];
      if (label) {
        breadcrumbs.push({ label, url });
      }

      return this.buildBreadCrumb(child, url, breadcrumbs);
    }
  }

  // password and confirm password check
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  checkMaxValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors['limit']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value > limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }

  checkMinValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors['limit']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value < limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }

  /**
   * Translate text
   */
  translate(path): String {
    return this._translocoService.translate(path)
  }

  showSuccess(message: String) {
    return this._toaster.success(message.toString(), '', { progressBar: true });
  }

  showSuccessSwal(message) {
    Swal.fire(
      {
        title: message,
        heightAuto: false,
        icon: 'success'
      }
    )
  }

  welcomeUser(message: String) {
    return this._toaster.success("Welcome to " + environment.appName, 'Hey, ' + message, { progressBar: true });
  }
  showFailure(message: String) {
    return this._toaster.error(message.toString());
  }
  showFailureSwal(message) {
    Swal.fire(
      {
        title: message,
        heightAuto: false,
        icon: 'error'
      }
    )
  }
  showWarning(message: String) {
    return this._toaster.warning(message.toString());
  }

  showBottomSuccess(message: String) {
    this._toaster.show(message.toString(), '', {
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr process-toaster',
    });
  }
  showProcess(message: String, id) {
    return this._toaster.show(message.toString(), '',
      {
        positionClass: 'toast-bottom-center',
        toastClass: 'ngx-toastr process-toaster',
        timeOut: 0,
        tapToDismiss: false,
        progressBar: true
      }).toastId = id;
  }

  removeProcess(id) {
    this._toaster.remove(id);
  }

  validationList: any[] = [
    { 'name': "At least one digit", "status": false },
    { 'name': "min 8 character", "status": false },
    { 'name': "At least one lower case", "status": false },
    { 'name': "At least one upper case", "status": false },
    { 'name': "At least one special character", "status": false },
  ];

  warningDialog(message, confirmText = "Yes", denyText = "No") {
    let parser = new DOMParser();
    let tg = parser.parseFromString(message, 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: confirmText,
      cancelButtonText: denyText,
      confirmButtonColor: 'green',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  deleteDialogue(data,
    message = 'swal.message.are_you_sure_to_remove',
    confirmText = 'swal.title.yes_remove_it') {
    let parser = new DOMParser();
    let tg = parser.parseFromString(this._translocoService.translate(message) + (data ? ' <b>' + data + '</b>?' : ''), 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: this._translocoService.translate(confirmText),
      cancelButtonText: this._translocoService.translate('swal.title.no_keep_it'),
      confirmButtonColor: 'red',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  deleteInputDialogue(data) {
    let parser = new DOMParser();
    let tg = parser.parseFromString(this._translocoService.translate('swal.message.are_you_sure_to_remove') + ' <b>' + data + '</b>?', 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      input: 'text',
      inputPlaceholder: this._translocoService.translate('swal.message.please_enter_data', { data: data }),
      inputValidator: (inputValue) => {
        if (!inputValue)
          return this._translocoService.translate('swal.validation.please_enter_text');
        else if (inputValue != data)
          return this._translocoService.translate('swal.validation.text_doesn_t_match');
        else
          return '';
      },
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: this._translocoService.translate('swal.title.yes_remove_it'),
      cancelButtonText: this._translocoService.translate('swal.title.no_keep_it'),
      confirmButtonColor: 'red',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  agreeDialogue(title, text, inputText, validationText, confirmText = "Yes", denyText = "No") {
    let parser = new DOMParser();
    return Swal.fire({
      title: this._translocoService.translate(title),
      text: this._translocoService.translate(text),
      input: 'checkbox',
      icon: 'warning',
      inputValue: 0,
      inputPlaceholder:
        this._translocoService.translate(inputText),
      inputValidator: (inputValue) => {
        if (!inputValue) {
          Swal.disableButtons()
          return this._translocoService.translate(validationText);

        }
        else
          return '';
      },

      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: this._translocoService.translate(confirmText),
      cancelButtonText: this._translocoService.translate(denyText),
      confirmButtonColor: 'green',
      heightAuto: false,
      cancelButtonColor: '#dadada',
    });
  }

  restoreDialogue(data) {
    let parser = new DOMParser();
    let tg = parser.parseFromString(this._translocoService.translate('swal.message.are_you_sure_to_restore') + ' <b>' + data + '</b>?', 'text/html').body;
    console.log(tg)
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      confirmButtonText: this._translocoService.translate('swal.title.yes_restore_it'),
      cancelButtonText: this._translocoService.translate('swal.title.no_keep_it'),
      confirmButtonColor: '#01a317',
      heightAuto: false,
      cancelButtonColor: '#dadada',
    });
  }

  generatePassword(length?: number) {
    const passwordLength = length || 12;
    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['!', '?', '@', ')', '(', '%', '$', '#'];

    const getRandom = array => array[Math.floor(Math.random() * array.length)];
    let finalCharacters = '';
    finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    finalCharacters = finalCharacters.concat(getRandom(numbers));
    finalCharacters = finalCharacters.concat(getRandom(symbols));
    for (let i = 0; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }
    return finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
  }

  toProperCase(str) {
    let newStr = str.replace('-', ' ').replace('_', ' ')
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  };

  toSnakeCase(str) {
    let newStr = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(s => s.toLowerCase())
      .join('_');
    return newStr;
  };

  generateHoursInterval = (
    startHourInMinute,
    endHourInMinute,
    interval,
  ) => {
    let times: any[] = [];

    for (let i = 0; startHourInMinute < 24 * 60; i++) {
      if (startHourInMinute > endHourInMinute + interval) break;

      var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

      var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

      times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);

      startHourInMinute = startHourInMinute + interval;
    }

    return times;
  };


  public loadScript(url) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  counter(i: number) {
    return new Array(i);
  }

  goToLogin(ref, target) {
    let redirectTo = new URL(environment.loginDomain)
    redirectTo.searchParams.set('reference', ref)
    redirectTo.searchParams.set('portal', environment.appSlug)
    switch (target) {
      case 'self':
        window.location.href = redirectTo.href
        break;
      case 'blank':
        window.open(redirectTo, '_blank')
        break;
      case 'replace':
        window.location.replace(redirectTo.href)
        break;
    }
  }

  dateConvert(date) {
    return date ? formatDate(date, 'yyyy-MM-dd', 'en-in') : ''
  }

  dirtyCheck<U>(source: Observable<U>) {
    return function <T>(valueChanges: Observable<T>): Observable<boolean> {
      const isDirty$ = combineLatest(
        source,
        valueChanges,
      ).pipe(
        debounceTime(300),
        map(([a, b]) => _.isEqual(a, b) === false),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
      return isDirty$;
    };
  }

}
