import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaFromArray'
})
export class CommaFromArrayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    return value.map(s => s['partner_food_menu_variant.variant_name']).toString();
  }

}
