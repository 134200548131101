/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Modal
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminLayoutService } from '../admin-layout.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  url: any;
  isLoading = true
  hasError = false
  hotelData: any;

  constructor(
    public dialogRef: MatDialogRef<ShareModalComponent>,
    private _adminLayoutService: AdminLayoutService,
    private _cd: ChangeDetectorRef
  ) {
    // Get hotel data
    this._adminLayoutService.hotelSubscription.subscribe(data => {
      this.hotelData = data
    })

  }

  ngOnInit(): void {
    this.isLoading = true
    this._adminLayoutService.getShareLinks().then((data) => {
      if (data) {
        this.url = data
        this._cd.detectChanges()
        this.isLoading = false
      }
      else {
        this._adminLayoutService.addShareLink().then((data: any) => {
          this.url = data.data
          this._cd.detectChanges()
        }).catch((error) => {
          this.hasError = true
        }).finally(() => {
          this.isLoading = false
        })
      }
    }).catch((error) => {
      this.hasError = true
      this.isLoading = false
    })
  }
}
