<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{t('action.share')}}</h1>
  <!-- No record message -->
  <div *ngIf="!hasError && !isLoading && !url" class="table-message">
    <mat-icon class="text-yellow-500">warning_amber</mat-icon>
    <span>{{t('emenu.message.no_links_found')}}</span>
  </div>
  <!-- Error message -->
  <div *ngIf="hasError && !isLoading" class="table-message">
    <mat-icon class="text-red-600">error_outline</mat-icon>
    <span>{{t('emenu.message.error_while_generating_links')}}</span>
  </div>
  <!-- Loading message -->
  <div *ngIf="!hasError && isLoading" class="table-message">
    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" style="vertical-align:middle; display: inline-block;"
      class="inline-block text-green-500">
    </mat-progress-spinner> <span class="ml-3">{{t('emenu.message.generating_links')}}</span>
  </div>

  <ng-container *ngIf="!isLoading && url">
    <share-buttons [theme]="'circles-dark'" [title]="hotelData.business_name"
      [include]="['whatsapp','telegram','facebook','copy','twitter','linkedin','pinterest','messenger','sms','email']"
      [show]="4" [showText]="true" [url]="url"></share-buttons>
  </ng-container>
  <div mat-dialog-actions [align]="'end'">
    <!-- Cancel -->
    <button mat-button type="button" (click)="dialogRef.close()" class="add-round-button">{{t('action.close')}}</button>
  </div>
</ng-container>